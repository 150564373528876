<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>FB管理画面の確認方法</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>アドタスカルからMeta広告へのAPI送信が成功しましたら、Meta広告の管理画面へ反映されるか確認する方法を説明します。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
        <h4>【１】データソースからピクセルを選択し、イベント「購入」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no03/01.png`" alt="" />
        <p>成果が反映されます。</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no03/02.png`" alt="" />
        <p>「詳細を見る」をクリックしてください。</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no03/03.png`" alt="" />
        <h4>▼イベント概要</h4>
        <p>「イベント概要」でも確認することができます。ASPのCV日時で計上されます。</p>
        <p>少し時間が経過してから反映されます。</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no03/04.png`" alt="" />
        <h4>▼最近のアクティビティ</h4>
        <p>API送信後、ほぼリアルタイムで反映されます。※「最新のアクティビティ」に反映されなくてもキャンペーンには反映されなる場合もあります。</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no03/05.png`" alt="" />
        <ul>
          <li>イベント時間：API送信日時</li>
          <li>URL：プログラム情報登録で設定したCVURL</li>
          <li>イベントパラメーター</li>
        </ul>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no03/06.png`" alt="" />
        </div>
        <ul>
          <li>value：プログラム情報登録で設定した報酬額＊承認率</li>
          <li>currency：jpy</li>
          <li>content_name：atid</li>
        </ul>
        <h4>▼キャンペーン</h4>
        <p>【１】<router-link :to="`/manual/${tool.codeKebab}/01/3`">広告マネージャ</router-link>の画面にアクセスし、キャンペーンの「結果」にマウスオーバーすると「ウェブサイト購入」に反映されます。</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no03/07.png`" alt="" />
        <p>時間帯別で確認したい場合は、</p>
        <p>「内訳」→「配信」→「時間帯（広告アカウントの時間帯）の順にクリックしてください。</p>
        <p>ASPのCV日時で計上されます。</p>
        <p>少し時間が経過してから反映されます。（成果によっては反映しない場合もあります。）</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no03/08.png`" alt="" />
        <h4 style="color: red;">【補足】</h4>
        <p style="color: red;">現在、コンバージョンAPIの仕組み上、API送信をしても100％、キャンペーンに反映されるわけではありません。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.error {
  width: 100%;
  border-collapse: collapse;
  margin-left: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px){
    margin-left: 0!important;
  }
  & + .error {
    margin-top: 20px;
  }
  th,
  td {
    border: 1px solid;
    padding: 15px;
  }
  th {
    background-color: #e5e5e5;
  }
}
</style>
