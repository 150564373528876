<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ユニット登録</h2>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>垢BAN対策で複数のピクセルをユニットに登録しておくことで、すぐに復活させることができます。</li>
          <li>ユニットに複数のピクセルを登録することで、１つの成果を複数のピクセルにAPI送信することができます。</li>
        </ul>
        <p>参考：<router-link :to="`/manual/${tool.codeKebab}/04/05`">ユニットとは？</router-link></p>
        <h3>【所要時間】</h3>
        <p>3分</p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h4>【１】<router-link :to="`/tools02/${tool.codeKebab}`">
          <span v-if="tool.codeKebab === 'facebook-cv'">Meta広告コンバージョンAPI連携ツール</span>
          <span v-else>{{tool.mediaName}}成果連携ツール</span>
        </router-link>のトップページから、「ユニット登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no04/no06/01.png`" alt="" />
        <h4>【２】「新規追加」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no04/no06/02.png`" alt="" />
        <h4>【３】以下の情報を入力し、「登録」をクリックします。</h4>
        <ul>
          <li>ユニット名：任意で命名</li>
          <li>一言メモ：当ユニットに関するメモ書きを任意で入力</li>
          <li>ピクセルを選択：当ユニットでまとめるピクセルを選択</li>
        </ul>
        <p>※ピクセル情報を登録していないと、ピクセル情報が表示されません。</p>
        <p style="color: red;">※複数のピクセルを登録することもできます（複数のビジネスマネージャにまたがってもOKです）。</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no04/no06/03.png`" alt="" />
        <h4>【４】ユニットが登録されました。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no04/no06/04.png`" alt="" />
        <p>以上で、ユニット登録は完了です。</p>
        <p>「<router-link :to="`/manual/${tool.codeKebab}/05/01`">プログラム登録</router-link>」に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualTools02Contents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
