<template>
  <div class="ctrl-home-buttons">
    <h2>一般</h2>
    <el-row class="items" :gutter="50">
      <el-col :span="8">
        <router-link to="/ctrl/post">
          <Button2 msg="お知らせ投稿" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/send-mails">
          <Button2 msg="メール一斉送信" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/users">
          <Button2 msg="ユーザー検索" />
        </router-link>
      </el-col>
    </el-row>
    <el-row class="items" :gutter="50">
      <el-col :span="8">
        <router-link to="/ctrl/gives-points">
          <Button2 msg="ポイント付与" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/affiliate">
          <Button2 msg="アフィリエイト" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/value-commercers">
          <Button2 msg="バリューコマース公開設定" />
        </router-link>
      </el-col>
    </el-row>
    <h2>広告成果連携ツール</h2>
    <el-row :gutter="50">
      <el-col :span="8">
        <router-link to="/ctrl/parameter-log">
          <Button2 msg="パラメータログ" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/auto-import-log">
          <Button2 msg="自動登録履歴" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <Button2 msg="レポート" />
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :span="8">
        <router-link to="/ctrl/bridges">
          <Button2 msg="atidログ" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/api-histories">
          <Button2 msg="API送信履歴" />
        </router-link>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :span="8">
        <router-link to="/ctrl/tools02/cart-system">
          <Button2 msg="カートシステム" />
        </router-link>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :span="8">
        <router-link to="/ctrl/browser-test-tool">
          <Button2 msg="ブラウザテストツール" />
        </router-link>
      </el-col>
    </el-row>
    <div v-if="false">
      <el-row>
        <el-col>
          <h2>使っていない</h2>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="8">
          <router-link to="/ctrl/usage-summary">
            <Button2 msg="利用状況確認" />
          </router-link>
        </el-col>
        <el-col :span="8">
          <router-link to="/ctrl/qa">
            <Button2 msg="よくある質問" />
          </router-link>
        </el-col>
        <el-col :span="8">
          <router-link to="/ctrl">
            <Button2 msg="アナリティクス" />
          </router-link>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="8">
          <router-link to="/ctrl/active-confirm">
            <Button2 msg="活性確認" />
          </router-link>
        </el-col>
        <el-col :span="8">
          <router-link to="/ctrl/contact">
            <Button2 msg="お問い合わせ" />
          </router-link>
        </el-col>
        <el-col :span="8">
          <router-link to="/ctrl/user">
            <Button2 msg="顧客マスター" />
          </router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "buttons",
  data() {
    return {};
  },
  components: {
    Button2,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: left;
  border: none;
  margin-top: 35px;
}
</style>
