<template>
  <div class="tools02-units-unit-list">
    <el-row>
      <el-col :span="4">
        <a
          href="https://adtasukaru.wraptas.site/78b5e6e714bf4968adb2bc8b980e2498"
          target="_blank"
          rel="noopener noreferrer"
          >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <router-link to="unit-detail/0">
          <el-button type="primary" class="new-add">新規追加</el-button>
        </router-link>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <!-- ----------------- ↓↓ LINE ↓↓ ----------------- -->
        <table v-if="tool.codeKebab !== 'facebook-cv'" class="typeA stripe">
          <thead>
            <tr>
              <th>ユニット名</th>
              <th style="width: 50px"></th>
              <th style="width: 50px"></th>
            </tr>
          </thead>
          <tbody v-show="unitList.length > 0">
            <tr v-for="(unitData, index) in unitList" :key="index">
              <td>
                {{ unitData.name }}
              </td>
              <td>
                <router-link :to="`unit-detail/${unitData.id}`">
                  <el-button type="">編集</el-button>
                </router-link>
              </td>
              <td>
                <el-button
                  @click="deleteUnit(unitData.id, unitData.name)"
                  type="danger"
                  >削除</el-button
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- ----------------- ↑↑ LINE ↑↑ ----------------- -->

        <!-- ----------------- ↓↓ Facebook ↓↓ ----------------- -->
        <table v-else class="typeA stripe">
          <thead>
            <tr>
              <th>ユニット名</th>
              <th style="width: 200px">ユニットトークン</th>
              <th style="width: 50px"></th>
              <th style="width: 50px"></th>
            </tr>
          </thead>
          <tbody v-show="units.length > 0">
            <tr v-for="(unitData, index) in units" :key="index">
              <td>
                {{ unitData.unitName }}
              </td>
              <td>
                {{ unitData.unitToken }}
              </td>
              <td>
                <router-link :to="`unit-detail/${unitData.unitId}`">
                  <el-button type="">編集</el-button>
                </router-link>
              </td>
              <td>
                <el-button
                  @click="delUnit(unitData)"
                  type="danger"
                  >削除</el-button
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- ----------------- ↑↑ Facebook ↑↑ ----------------- -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "Tools02PromotionsPromotionList",
  components: {
    Button2,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools"]),
    unitList() {
      return this.$store.getters["tools02/unitListInTool"](this.tool.id);
    },
    units() {
      return this.$store.getters["tools02/unitsInTool"]("8");
    },
  },
  watch: {},
  methods: {
    //
    deleteUnit(unitId, unitName) {
      //
      let confirmText = `ユニット名「${unitName}」のユニット情報を削除します。よろしいですか？`;
      if (confirm(confirmText)) {
        // through~.
      } else {
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("unit_id", unitId);
      this.$axios
        .post(`${this.rt}/l/api/tools02/unit/delete`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.$store.dispatch("tools02/setUnitList");
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //

    //
    delUnit(unitData) {
    let message = ``;
      message += `本当にユニットを削除しても大丈夫ですか？\n`;
      message += `\n`;
      message += `・${unitData.unitName}（${unitData.unitToken}）\n`;
      message += `\n`;
      message += `LPタグ、中間ページタグはユニットごとに異なります。\n`;
      message += `削除したユニットから成果が発生した場合、API送信できません。\n`;
      message += `\n`;
      message += `ユニットを削除する前にLPタグ、中間ページタグを外すか差し替えてください。\n`;

      // 
      if (
        confirm(message)
      ) {
        let params = new URLSearchParams();
        params.append("unit_id", unitData.unitId);
        this.$axios
          .post(`${this.rt}/api/tools02/delUnit.php`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch("tools02/setUnits");
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    //
  },
};
</script>

<style lang="scss" scoped>
</style>
