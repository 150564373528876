<template>
  <div class="manual-google-cv-contents">
    <h2>アドタスカルにプログラム情報を登録する</h2>

    <el-row>
      <el-col>
        <h3>目的</h3>
        <p>アドタスカルにプログラム情報を登録します。</p>

        <h3>所要時間</h3>
        <p>5分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <h4>
          【１】<router-link to="/tools02/google-cv/accounts">
            「Google広告アカウント＆プログラム情報」</router-link
          >をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no01/01.png`" alt="" />

        <h4>【２】「プログラム情報登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no01/02.png`" alt="" />

        <p>
          ※まだGoogle広告アカウントを登録していない場合は、「4.Google広告アカウント登録」を参考に登録してください。
        </p>

        <h4>【３】「プログラム新規登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no01/03.png`" alt="" />

        <h4>【４】プログラム情報を入力します。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no01/04.png`" alt="" />
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <h4>【１】「<router-link to="/tools02/yahoo-cv/accounts">Yahoo!検索広告アカウント&ASPプログラム情報</router-link>」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no05/no01/01.png`" alt="" />
        <h4>【２】「プログラム情報登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no05/no01/02.png`" alt="" />
        <h4>【３】「プログラム新規登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no05/no01/03.png`" alt="" />
        <h4>【４】「プログラム情報」を入力します。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no05/no01/04.png`" alt="" />
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'facebook-cv'">
        <h4>【１】「<router-link :to="`/tools02/${tool.codeKebab}`">Meta広告コンバージョンAPI連携ツール</router-link>」のトップページから、「プログラム登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no05/no01/01.png`" alt="" />
        <h4>【２】「プログラム新規登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no05/no01/02.png`" alt="" />
        <h4>以下の画面を参照し、プログラム情報を入力し、「登録」をクリックします。CVイベントは、「標準イベント（Meta広告が用意したもの）」と「カスタムイベント（自由入力）」があり、どちらも設定可能です。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no05/no01/03.png`" alt="" />
        <p>CVイベントで「カスタムイベント」を選択すると、「カスタムイベント」の入力欄が表示されますので、任意の値を入力してください。（半角英数字※50文字未満）</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no05/no01/04.png`" alt="" />
      </el-col>
    </el-row>
    <template v-if="tool.codeKebab === 'facebook-cv'">
      <el-row>
        <el-col>
          <h4>【注意事項1】プログラム名とプログラムIDは、ASPごとに仕様が異なります。 各ASPごとにマニュアルを用意したので、以下のURLにアクセスし、「初期設定（プログラム情報登録）」を確認ください。</h4>
          <p><a
            href="https://tools.adtasukaru.com/manual/facebook-cv"
            target="_blank"
            rel="noopener noreferrer"
            >https://tools.adtasukaru.com/manual/facebook-cv</a
          ></p>
          <p>プログラム登録が完了したら、「<router-link :to="`/manual/${tool.codeKebab}/06/01`">パラメータ引き継ぎ</router-link>」に進んでください。</p>
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <el-row>
        <el-col>
          <h4>
            【注意事項1】プログラム名とプログラムIDは、ASPごとに仕様が異なります。
            各ASPごとにマニュアルを用意したので確認ください。
          </h4>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <div class="inner">
            <div
              class="single"
              v-for="(manualData, index) in manualList"
              :key="index"
              v-show="manualData.name !== ''"
            >
              ■&nbsp;<span
                class="link-asp"
                @click="GotoManual(manualData.cate01, manualData.cate02)"
                >{{ manualData.name }}</span
              >
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <div>
            <h4>
              【注意事項2】オフラインCV名は、{{tool.mediaName}}で作成したコンバージョン名を間違えずに入力してください。
            </h4>
            <p>
              ⇒
              <router-link :to="`/manual/${tool.codeKebab}/05/02`"
                >オフラインCV名のミスしない入力方法</router-link
              >
            </p>
          </div>


          <h4>
            【注意事項３】まだオフラインコンバージョンを作成していない場合は、「<router-link
              :to="`/manual/${tool.codeKebab}/03/02`"
              >オフラインコンバージョンの作成</router-link
            >」を参考に作成してください。
          </h4>

          <div class="end">
            <p>これでアドタスカルへのプログラム情報の登録は完了です。</p>

            <p>
              次は、「<router-link :to="`/manual/${tool.codeKebab}/06/01`"
                >パラメータ引き継ぎ設定とは？</router-link
              >」に進んでください。
            </p>
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "tools",
      "asps",
      "apiErrorMessage",
      "breadCrumbList",
    ]),
    manualList() {
      let manualSectionData = this.tools[this.tool.codeCamel].manualSectionList.find(
          (sectionData) => sectionData.section === "e"
        )
      let manualList = manualSectionData.manualList;

      let additionalManualList = [
        {
          cate01: "05",
          cate02: "08",
          kubun: "ASP→アドタスカル",
          name: "BULK-AD"
        },
        {
          cate01: "05",
          cate02: "08",
          kubun: "ASP→アドタスカル",
          name: "SIXPACK-C"
        },
        {
          cate01: "05",
          cate02: "18",
          kubun: "ASP→アドタスカル",
          name: "@tension"
        },
        {
          cate01: "05",
          cate02: "18",
          kubun: "ASP→アドタスカル",
          name: "Tempura-Link"
        },
        {
          cate01: "05",
          cate02: "18",
          kubun: "ASP→アドタスカル",
          name: "twowin"
        },
        {
          cate01: "05",
          cate02: "18",
          kubun: "ASP→アドタスカル",
          name: "ゴリラテ"
        },
        {
          cate01: "05",
          cate02: "18",
          kubun: "ASP→アドタスカル",
          name: "Arrows"
        },
        {
          cate01: "05",
          cate02: "18",
          kubun: "ASP→アドタスカル",
          name: "i-counter"
        },
        {
          cate01: "05",
          cate02: "18",
          kubun: "ASP→アドタスカル",
          name: "クラウン"
        },
        {
          cate01: "05",
          cate02: "18",
          kubun: "ASP→アドタスカル",
          name: "アシスタ"
        }

      ];

      additionalManualList.forEach(additionalManualData => {
        manualList.push(additionalManualData);
      });
      return manualList;
    },
  },
  methods: {
    GotoManual(cate01, cate02) {
      var link = `/manual/${this.tool.codeKebab}/${cate01}/${cate02}`;
      this.$router.push(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  display: flex;
  flex-wrap: wrap;
  .single {
    width: 25%;
    height: 3em;
    line-height: 3em;
    @media screen and (max-width: 768px){
      width: 100%;
      margin-left: 30px;
    }
    .link-asp {
      text-decoration: underline;
      cursor: pointer;
      color: #409eff;
    }
  }
}
table {
  width: 95%;
  margin-left: 40px;

  td {
    width: 25%;
  }

  td {
    padding: 25px;
  }
}
a {
  color: #409eff;
  font-weight: bold;
}

.end {
  margin-left: 0;
  margin-top: 50px;
  p{
    @media screen and (max-width: 768px){
      margin-left: 0;
    }
  }
}

img {
  image-rendering: -webkit-optimize-contrast;

  @media screen and (max-width: 768px){
    margin-left: 0!important;
    width: 100%!important;
  }
}
</style>
