<template>
  <div class="ctrl-value-commercers-value-commercer-list">
    <el-table v-loading="loading === true" :data="list" border class="point-back-history-list"
      :row-class-name="tableRowClassName">
      <el-table-column prop="userId" label="ID" width="90px">
      </el-table-column>
      <el-table-column label="名前" width="150px">
        <template slot-scope="scope">
          <span>{{ scope.row.name01 }} {{ scope.row.name02 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="valueCommerceContractorName" label="契約者名"></el-table-column>
      <el-table-column prop="valueCommerceContractorId" label="契約者ID"></el-table-column>
      <el-table-column prop="valueCommerceApplicationDatetime" label="申請日時"></el-table-column>
      <el-table-column label="解放日時">
        <template slot-scope="scope">
          <span v-if="scope.row.valueCommerceReleaseDatetime === null">-</span>
          <span v-else>{{ scope.row.valueCommerceReleaseDatetime }}</span>
        </template>
      </el-table-column>
      <el-table-column width="120px">
        <template slot-scope="scope">
          <div v-if="scope.row.valueCommerceReleaseDatetime === null" class="center">
            <el-button @click="release(scope.row)" type="primary">解放</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "value-commercer-list",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      list: []
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
  },
  created() {
    this.getValueCommercerList();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(rowIndex);

      // 
      let className = '';
      if (row.valueCommerceReleaseDatetime === null) {
        // 
      } else {
        className = 'released';
      }

      // 
      return className;
    },
    getValueCommercerList() {
      // 
      this.loading = true;

      // 
      this.$axios
        .get(`${this.rt}/l/api/user/get-value-commercer-list`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.list = response.data.list;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.loading = false;
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    release(userData) {
      // 
      let message = ``;
      message += `ID: ${userData.userId}\n`;
      message += `名前: ${userData.name01}${userData.name02}\n`;
      message += `契約者名: ${userData.valueCommerceContractorName}\n`;
      message += `契約者ID: ${userData.valueCommerceContractorId}\n`;
      message += `申請日時: ${userData.valueCommerceApplicationDatetime}\n`;
      message += `\n`;
      message += `このユーザーにバリューコマース用の通知URLを公開します。よろしいですか？`;

      // 
      if (confirm(message)) {
        // 
        let params = new URLSearchParams();
        params.append('user_id', userData.userId);
        this.$axios
          .post(`${this.rt}/l/api/user/release-value-commerce`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              alert("解放しました。");
              this.getValueCommercerList();
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
            }
            return;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        return;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &.released {
        background-color: #aaa;
      }
    }
  }
}
</style>
