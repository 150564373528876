import { render, staticRenderFns } from "./ContactButton.vue?vue&type=template&id=436dd776&scoped=true&"
import script from "./ContactButton.vue?vue&type=script&lang=js&"
export * from "./ContactButton.vue?vue&type=script&lang=js&"
import style0 from "./ContactButton.vue?vue&type=style&index=0&id=436dd776&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436dd776",
  null
  
)

export default component.exports