<template>
  <div class="ui-contact-button">
    <div v-show="contactFormIsOpen" class="contact-form">
      <div class="close"><span @click="closeContactForm">×</span></div>
      <h3>お問い合わせ・よくある質問</h3>
      <el-row>
        <el-col>
          <div class="question-cover">
            <div class="question">
              <div class="question-title"><span>■&ensp;ツール別</span></div>
              <div class="question-des">
                <a
                  href="https://adtasukaru.wraptas.site/2b3dfcd921504bbba9ca6b50db1cc4e3#block-c832d6ddaf9240f68234ed404d45b5de"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Google広告成果連携ツール</a
                >
                <a
                  href="https://adtasukaru.wraptas.site/9c70dc194ff24ba3a6eac63e462e929e#block-a094a6a872a94ba391cd1b5efa559e74"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Yahoo!検索広告成果連携ツール</a
                >
                <a
                  href="https://adtasukaru.wraptas.site/795888df7ae14871a17ec5125b034a67#block-4c64aefd06aa4b5bb3c3af97478e790d"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Meta広告成果連携ツール</a
                >
                <a
                  href="https://adtasukaru.wraptas.site/a5764ede319449b7a02e613ddc1cd700#block-dc6edf0cccd04b5d9da9138b483effea"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Microsoft検索広告成果連携ツール</a
                >
              </div>
            </div>
            <div class="question">
              <div class="question-title">■&ensp;全ツール共通</div>
              <div class="question-des all">
                <a
                  href="https://adtasukaru.wraptas.site/322a417de2694d4b827d82f08e548692#block-96c7352ff1cb40bb8d779a22eace1abf"
                  target="_blank"
                  rel="noopener noreferrer"
                  >パラメータ引き継ぎ</a
                >

                <a
                  href="https://adtasukaru.wraptas.site/322a417de2694d4b827d82f08e548692#block-8670d79eff4b4469a720b5effe910f45"
                  target="_blank"
                  rel="noopener noreferrer"
                  >ASP連携</a
                >
                <a
                  href="https://adtasukaru.wraptas.site/322a417de2694d4b827d82f08e548692#block-49a22521527a4f469696ee8157d644ab"
                  target="_blank"
                  rel="noopener noreferrer"
                  >その他</a
                >
              </div>
            </div>
            <div class="question">
              <div class="question-title"><span>■&ensp;よくある質問</span></div>
              <div class="question-des">
                <a
                  href="https://adtasukaru.wraptas.site/465c91d2f7c84647a055f158af705624"
                  target="_blank"
                  rel="noopener noreferrer"
                  >キャンペーンにCVが反映しません。</a
                >
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="12" class="email-to">
          <h4>返信先アドレス（ご登録のアドレスから選択）</h4>
          <!-- <div class="email">{{ userData["email"] }}</div> -->
          <el-select v-model="email">
            <el-option :label="userData['email']" :value="userData['email']" selected="selected"></el-option>
            <el-option
            v-for="email in mailList"
            :key="email.id"
            :label="email.email"
            :value="email.email"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12" style="text-align: left">
          <h4>お問い合わせ区分（必須）</h4>
          <el-select v-model="contactType" placeholder="選択してください">
            <el-option
              v-for="item in contactTypes"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="contact-text">
        <h4>お問い合わせ内容（必須）</h4>
        <el-input
          v-model="message"
          type="textarea"
          :autosize="{ minRows: 10 }"
        />
      </div>
      <div class="select-file">
        <span>添付ファイル</span>
        <input v-on:change="fileSlected($event)" type="file" multiple />
        <!-- <input v-on:change="fileSlected($event)" type="file" multiple accept="image/*" /> -->
        <div>
          <a href="https://adtasukaru.wraptas.site/652116b4cb7045469cd2dbc99de053fc" target="_blank" rel="noopener noreferrer">※複数画像を添付する方法</a>
        </div>
      </div>
      <el-button @click="sendQuestion" type="primary">送信</el-button>
    </div>
    <div class="contact-button" @click="switchContactForm">
      <img slot="reference" :src="`${rt}/img/contact-button.png`" />
      <div>お問い合わせ</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UiContactButton",
  props: {},
  data() {
    return {
      uploadedFile: null,
      contactFormIsOpen: false,
      // contactFormIsOpen: true,
      contactType: "",
      contactTypes: [
        { value: 0, name: "質問" },
        { value: 1, name: "要望" },
      ],
      message: "",
      mailList: [],
      email: "",
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "apiErrorMessage",
      "userData",
      "imgLimit",
      "imgLimitMessage",
      "imgTotalLimit",
      "imgTotalLimitMessage",
      "imgTotalCount",
      "imgTotalCountMessage",
    ]),
  },
  mounted() {
    this.getMailList();
    this.email = this.userData['email'];
  },
  methods: {
    switchContactForm() {
      console.log(
        `このページのURLは${this.$route.fullPath}-${this.$route.meta.title}`
      );
      
      this.checkLogin();


      this.contactFormIsOpen = this.contactFormIsOpen === true ? false : true;
    },
    checkLogin() {
      this.$axios
      .get(`${this.rt}/api/login-check.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          return;
        } else if (res.data.status === 400) {
          this.loading = false;
          alert(res.data.message);
          return;
        } else if (res.data.status === 401) {
          alert(res.data.message);
          this.$router.push("/login");
          return;
        } else {
          this.loading = false;
          alert(this.apiErrorMessage);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    closeContactForm() {
      this.contactFormIsOpen = false;
    },
    toQA() {
      this.closeContactForm();
      this.$router.push("/qa");
    },
    sendQuestion() {
      let formData = new FormData();
      formData.append("page_path", this.$route.fullPath);
      formData.append("page_title", this.$route.meta.title);
      formData.append("contact_type", this.contactType);
      formData.append("email", this.email);
      if (this.uploadedFile !== null) {
        this.uploadedFile.forEach((file) =>{
          formData.append("uploaded_file[]", file);
        });
      }else {
          formData.append("uploaded_file", null);
      }
      formData.append("message", this.message);
      let config = { headers: { "content-type": "multipart/form-data" } };
      this.$axios
        .post(`${this.rt}/api/sendQuestion.php`, formData, config)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.uploadedFile = null;
            alert("送信しました。");
            this.closeContactForm();
          } else if (res.data.status === 400 || res.data.status === 500) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fileSlected(e) {
      var size = e.target.files[0].size;
      if (size > this.imgLimit) {
        alert(this.imgLimitMessage);
        return;
      } else {
        e.preventDefault();
        let files = e.target.files;
        this.uploadedFile = files;
        this.$forceUpdate();
      }

      let fileList = Array.from(e.target.files);

      if (fileList.length > this.imgTotalCount) {
        alert(this.imgTotalCountMessage);
        e.target.value = '';
        return;
      } else {
        // no action.
      }

      let totalSize = 0;
      fileList.forEach((file) => {
        totalSize += file.size;
      });

      if (totalSize > this.imgTotalLimit) {
        alert(this.imgTotalLimitMessage);
        e.target.value = '';
        return;
      } else {
        // no action.
      }

      this.uploadedFile = fileList;
      
    },
    getMailList() {
      this.$axios
        .get(`${this.rt}/l/api/user/get-mail-list`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.mailList = response.data.list;
          } else {
            //
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return this.mailList;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-contact-button {
  @media screen and (max-width: 767px) {
    display: none;
  }
  .contact-form {
    * {
      margin: 10px 0;
    }
    position: fixed;
    right: 9%;
    bottom: 12%;
    z-index: 999;
    box-sizing: border-box;
    border: solid 2px #bbb;
    border-radius: 5px;
    padding: 20px 15px;
    width: 950px;
    text-align: center;
    background-color: #fff;
    h4 {
      text-align: left;
    }
    .email-to {
      .email {
        border: 1px solid #ddd;
        border-radius: 5px;
        text-align: left;
        padding: 7px;
      }
    }
    .contact-text {
      .el-textarea {
        margin: 0;
      }
    }
    .el-select {
      margin: 0;
      width: 100%;
    }
    .close {
      text-align: right;
      margin: 0;
      margin-right: 10px;
      font-size: 36px;
      position: absolute;
      right: 20px;
      top: 5px;
      span {
        cursor: pointer;
      }
    }
    h3 {
      margin: 0;
    }
    .qa {
      text-decoration: underline;
      color: var(--link-blue);
      cursor: pointer;
      display: inline-block;
    }
    .select-file {
      text-align: left;
      span {
        margin-right: 20px;
      }
      a{
        text-decoration: none;
        color: #409efe;
      }
    }
  }
  .contact-button {
    position: fixed;
    right: 9%;
    bottom: 5%;
    cursor: pointer;
    text-align: center;
    img {
      width: 50px;
    }
  }
  .question-cover {
    width: 85%;
    margin: 0 auto;
    a{
      text-decoration: none;
      color: #409efe;
    }
    .question {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;

      .question-title {
        width: 30%;
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
      }
      .question-des {
        width: 70%;
        display: flex;
        justify-content: flex-start;
        margin-top: 0;
        margin-bottom: 0;
        flex-wrap: wrap;
        a {
          width: 50%;
          text-align: left;
        }
      }
      .all{
        a {
          width: 33%;
          text-align: center;
        }
      }
    }
  }
}
</style>