<template>
  <div class="send-value-commerce-data-value-commerce-data-register">
    <el-row type="flex" justify="center">
      <el-col :span="8">
        <div class="inputs-cover">
          <div class="input-cover">
            <el-input v-model="contractorName" placeholder="田中太郎">
              <template slot="prepend">契約者名</template>
            </el-input>
            <ValidationErrorMessages :errorMessageList="validationErrorData.contractorName" />
          </div>
          <div class="input-cover">
            <el-input v-model="contractorId" placeholder="1234567">
              <template slot="prepend">契約者ID</template>
            </el-input>
            <ValidationErrorMessages :errorMessageList="validationErrorData.contractorId" />
          </div>
        </div>
        <div class="button-cover">
          <el-button @click="send()" type="primary">送信</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";

export default {
  name: "value-commerce-data-register",
  components: {
    ValidationErrorMessages,
  },
  props: {
    // 
  },
  data() {
    return {
      // 
      contractorName: "",
      contractorId: "",

      // 
      validationErrorData: {
        contractorName: [],
        contractorId: [],
      },
    };
  },
  computed: {
    ...mapGetters(['rt', 'apiErrorMessage']),
  },
  watch: {
  },
  methods: {
    send() {
      //
      let params = new URLSearchParams();
      params.append("contractor_name", this.contractorName);
      params.append("contractor_id", this.contractorId);
      this.$axios.post(`${this.rt}/l/api/user/register-and-send-value-commerce-data`, params)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            alert("送信しました。");
            this.$router.push("/");
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
          } else if (response.data.status === 409) {
            alert("すでに送信済みです。");
          } else {
            alert(this.apiErrorMessage);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.inputs-cover {
  .input-cover {
    &+.input-cover {
      margin-top: 0.5em;
    }
  }
}

.button-cover {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}
</style>
