<template>
  <div class="send-value-commerce-data">
    <el-row>
      <el-col>
        <h2>バリューコマース 自動連携依頼</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <ValueCommerceDataRegister />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ValueCommerceDataRegister from "@/components/SendValueCommerceData/ValueCommerceDataRegister.vue";

export default {
  components: {
    ValueCommerceDataRegister,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>