<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPの成果データをアドタスカルにインポートする</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>成果データをアドタスカルにインポートしていきます。</li>
          <li>アドタスカルにインポートすることで、{{tool.mediaName}}の仕様に自動変換することができます。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="tool.codeKebab === 'facebook-cv'">
        <h4>【１】「<router-link :to="`/${tool.codeKebab}`">Meta広告コンバージョンAPI連携ツール</router-link>」の「<router-link :to="`/tools02/${tool.codeKebab}/report`">ASP成果インポート・エクスポート</router-link>」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/report.png`" alt="" />
        <h4>【２】ASPの成果CSVをダウンロードしてください。（参照：<router-link :to="`/tools02/${tool.codeKebab}/csv-manual-links`">ASPごとの成果データのダウンロード方法</router-link>）</h4>
        <h4>【３】ASPを選択して、成果CSVをインポートします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no07/no14/01.png`" alt="" />
        <h4>【４】「インポート完了しました。」と表示されるので「OK」をクリックします</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no07/no14/02.png`" alt="" />
        </div>
        <h4>【５】インポート結果が表示されるので確認します。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no07/no14/03.png`" alt="" />
        <h4>※エラーが発生した場合、成果CSVを確認してください。</h4>
        <p>（●行目）→CSVの行と対応しています。</p>
        <p>上記の画像の場合、４行目と６行目を確認しましょう。</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no07/no14/04.png`" alt="" />
        <table class="error">
          <tr>
            <th>「クリックIDが取得不可」の場合</th>
            <td>パラメータの引継ぎ設定が上手くできていない可能性があります。<br>
            「<router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。</td>
          </tr>
          <tr>
            <th>「該当プログラム未登録」の場合</th>
            <td>プログラム情報の登録が済んでいないか、プログラム情報が間違っている可能性があります。<br>
        「<router-link :to="`/manual/${tool.codeKebab}/05/01`">プログラム情報を登録する</router-link>」をご確認ください。</td>
          </tr>
        </table>
      </el-col>
      <el-col v-else>
        <h4>【１】「<router-link :to="`/${tool.codeKebab}`">{{tool.mediaName}}成果連携ツール</router-link>」の「<router-link :to="`/tools02/${tool.codeKebab}/report`">ASP成果インポート・エクスポート</router-link>」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/report.png`" alt="" />
        <h4>【２】ASPを選択して、成果CSVをインポートします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no14/01.png`" alt="" />
        <h4>【３】「インポート完了しました。」と表示されるので「OK」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no14/02.png`" alt="" />
        </div>
        <h4>【４】インポート結果が表示されるので確認します。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no14/03.png`" alt="" />
        <h4>※エラーが発生した場合、成果CSVを確認してください。</h4>
        <p>（●行目）→CSVの行と対応しています。</p>
        <p>上記の画像の場合、５行目と８行目を確認しましょう。</p>
        <img :src="`${rt}/img/manual/google-cv/no07/no14/04.png`" alt="" />
        <table class="error">
          <tr>
            <th>「{{tool.clickIdName}}が取得不可」の場合</th>
            <td>パラメータの引継ぎ設定が上手くできていない可能性があります。<br>
            「<router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。</td>
          </tr>
          <tr>
            <th>「該当プログラム未登録」の場合</th>
            <td>プログラム情報の登録が済んでいないか、プログラム情報が間違っている可能性があります。<br>
        「<router-link :to="`/manual/${tool.codeKebab}/05/01`">プログラム情報を登録する</router-link>」をご確認ください。</td>
          </tr>
        </table>
        <!-- <h4>「{{tool.clickIdName}}が取得不可」の場合</h4> -->
        <!-- <h5>＜リスティング、GDN＞</h5> -->
        <!-- <p>パラメータの引継ぎ設定が上手くできていない可能性があります。</p>
        <p>「<router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。</p> -->
        <!-- <h5>＜YouTube広告＞</h5> -->
        <!-- <p>広告入稿が間違っている可能性があります。</p> -->
        <!-- <p>「<router-link :to="`/manual/${tool.codeKebab}/06/05`">YouTube広告の入稿手順</router-link>」をご確認ください。</p> -->
        <!-- <h4>「該当プロモーション未登録」の場合</h4>
        <p>プログラム情報の登録が済んでいないか、プログラム情報が間違っている可能性があります。</p>
        <p>「<router-link :to="`/manual/${tool.codeKebab}/05/01`">プログラム情報を登録する</router-link>」をご確認ください。</p> -->
      </el-col>
    </el-row>
    <el-row v-if="tool.codeKebab === 'facebook-cv'">
      <el-col>
        <p>全ての成果が「API送信成功」と表示されれば完了です。</p>
        <p>「API送信失敗」と表示された場合は、「API送信履歴」を確認してください。（参照：<router-link :to="`/manual/${tool.codeKebab}/10/02`">API送信履歴を確認する</router-link>）</p>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-col>
        <p>全ての成果が「正常にインポート完了」と表示されれば完了です。</p>
        <p>次は、「<router-link :to="`/manual/${tool.codeKebab}/07/15`">{{tool.mediaName}}アップロード用データをエクスポート</router-link>」に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
   ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.error {
  border-collapse: collapse;
  // margin-left: 20px;
  margin: 50px 20px;
  width: 97%;
  @media screen and (max-width: 768px){
    width: 100%;
    margin: 50px 0;
  }

  th,
  td {
    border: 1px solid;
    padding: 20px 15px;
    // width: 80%;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  th {
    background-color: #e5e5e5;
    width: 31%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  }
}
</style>
