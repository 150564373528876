<template>
  <div class="tools01-report-reports">
    <el-row>
      <el-col :span="20">
        <h3>
          {{ pageData.name }}
          <router-link
            :to="`/tools01/${toolData.code2}/edit/${pageData.siteId}`"
            ><el-button type="warning" style="margin-left: 30px"
              >編集</el-button
            ></router-link
          >
        </h3>
      </el-col>
      <el-col :span="4">
        <a :href="``">
          <Button2 type2="1" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>

    <!-- ------------------- ↓↓ 検索部分 ↓↓ ------------------- -->
    <el-row type="flex" align="middle">
      <el-col>
        <el-button
          @click="dateRange = g_getDateRange('thisMonth')"
          type="primary"
          >今月</el-button
        >
        <el-button @click="dateRange = g_getDateRange('today')" type="primary"
          >本日</el-button
        >
        <el-button
          @click="dateRange = g_getDateRange('yesterday')"
          type="primary"
          >昨日</el-button
        >
        <el-button @click="dateRange = g_getDateRange(30)" type="primary"
          >過去３０日</el-button
        >
        <el-button @click="dateRange = g_getDateRange(365)" type="primary"
          >全期間（過去365日間）</el-button
        >
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col>
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          start-placeholder="開始日"
          end-placeholder="終了日"
          value-format="yyyy-MM-dd"
          clearable
        >
        </el-date-picker
      ></el-col>
    </el-row>
    <!-- ------------------- ↑↑ 検索部分ここまで ↑↑ ------------------- -->

    <!-- ------------------- ↓↓ レポート ↓↓ ------------------- -->
    <div
      v-if="reportList.length === 0"
      v-loading="loading"
      style="height: 200px"
    ></div>
    <div v-else class="width-max-cover">
      <el-row
        v-for="(reportData, index) in reportList"
        :key="index"
        v-loading="loading"
      >
        <el-col v-if="reportData.name !== null">
          <h4>【{{ reportData.name }}】</h4>
        </el-col>
        <el-col v-if="reportData.type === 'daySection1'">
          <div class="toggle-cover">
            <span>表示分割領域ごとのデータを表示</span>
            <el-switch v-model="showDaySectionReport"> </el-switch>
          </div>
        </el-col>
        <template
          v-if="
            reportData.type === 'total' ||
            reportData.type === 'format' ||
            reportData.type === 'dayTotal' ||
            ((reportData.type === 'daySection1' ||
              reportData.type === 'daySection2' ||
              reportData.type === 'daySection3') &&
              showDaySectionReport === true)
          "
        >
          <el-col>
            <h5 v-if="reportData.type === 'dayTotal'">合計</h5>
            <h5 v-else-if="reportData.type === 'daySection1'">領域１</h5>
            <h5 v-else-if="reportData.type === 'daySection2'">領域２</h5>
            <h5 v-else-if="reportData.type === 'daySection3'">領域３</h5>
          </el-col>
          <el-col>
            <el-table :data="reportData.list" border>
              <!-- ------------ ↓↓ 期間集計 ↓↓ ------------ -->
              <template v-if="reportData.type === 'total'">
                <el-table-column fixed label="">
                  <template slot-scope="scope">
                    <span v-if="scope.row.section === '0'">合計</span>
                    <span v-else>表示分割領域{{ scope.row.section }}</span>
                  </template>
                </el-table-column>
              </template>
              <!-- ------------ ↑↑ 期間集計 ↑↑ ------------ -->

              <!-- ------------ ↓↓ フォーマット別 ↓↓ ------------ -->
              <template v-else-if="reportData.type === 'format'">
                <el-table-column fixed label="フォーマット名">
                  <template slot-scope="scope">
                    <span>{{ scope.row.formatName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="pageData.toolId === '1'"
                  fixed
                  label="分割位置"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.section }}</span>
                  </template>
                </el-table-column>
              </template>
              <!-- ------------ ↑↑ フォーマット別 ↑↑ ------------ -->

              <!-- ------------ ↓↓ フォーマット別 ↓↓ ------------ -->
              <template
                v-else-if="
                  reportData.type === 'dayTotal' ||
                  reportData.type === 'daySection1' ||
                  reportData.type === 'daySection2' ||
                  reportData.type === 'daySection3'
                "
              >
                <el-table-column fixed label="日付">
                  <template slot-scope="scope">
                    <span>{{ scope.row.date }}</span>
                  </template>
                </el-table-column>
              </template>
              <!-- ------------ ↑↑ フォーマット別 ↑↑ ------------ -->

              <!-- ------------ ↓↓ 共通部分 ↓↓ ------------ -->
              <el-table-column label="表示回数">
                <template slot-scope="scope">
                  <span>{{ scope.row.displayedCount | localeNum }}</span>
                </template>
              </el-table-column>
              <el-table-column label="クリック数">
                <template slot-scope="scope">
                  <span>{{ scope.row.clickedCount | localeNum }}</span>
                </template>
              </el-table-column>
              <el-table-column label="クリック率">
                <template slot-scope="scope">
                  <span>{{ scope.row.ctr }}%</span>
                </template>
              </el-table-column>
              <el-table-column label="CV数">
                <template slot-scope="scope">
                  <span>{{ scope.row.conversionCount | localeNum }}</span>
                </template>
              </el-table-column>
              <el-table-column label="CVR">
                <template slot-scope="scope">
                  <span>{{ scope.row.tcvr }}%</span>
                </template>
              </el-table-column>
              <el-table-column label="報酬額">
                <template slot-scope="scope">
                  <span>{{ scope.row.rewardTotal | localeNum }}円</span>
                </template>
              </el-table-column>
              <el-table-column label="EPP">
                <template slot-scope="scope">
                  <span>{{ scope.row.epp }}円</span>
                </template>
              </el-table-column>
              <!-- ------------ ↑↑ 共通部分 ↑↑ ------------ -->
            </el-table>
          </el-col>
        </template>
      </el-row>
    </div>
    <!-- ------------------- ↑↑ レポート ↑↑ ------------------- -->
    <ReportColumnDescription :toolId="toolData.toolId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReportColumnDescription from "@/components/Tools01/Report/ReportColumnDescription.vue";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "tools01-report-reports",
  components: {
    Button2,
    ReportColumnDescription,
  },
  props: {
    pageId: String,
  },
  data() {
    return {
      loading: true,
      dateRange: this.g_getDateRange("thisMonth"),
      showDaySectionReport: false,
      reportList: [],
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    pageData() {
      return this.$store.getters["tools01/pageData"](this.pageId);
    },
    toolData() {
      return this.$store.getters["toolData"](this.pageData.toolId);
    },
  },
  watch: {
    dateRange: {
      handler() {
        this.getReportList();
      },
      immediate: true,
    },
  },
  methods: {
    getReportList() {
      //
      this.loading = true;

      //
      let parameters = "";
      parameters += `?page_id=${this.pageId}`;
      parameters += `&tool_id=${this.pageData.toolId}`;
      parameters += `&ab_list=${JSON.stringify(["0", "a", "b"])}`;
      parameters += `&date_range=${JSON.stringify(this.dateRange)}`;
      this.$axios
        .get(`${this.rt}/l/api/tools01/log/get-report-list${parameters}`)
        .then((response) => {
          console.log(response);
          this.reportList = response.data.list;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.width-max-cover {
  .toggle-cover {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }
}
</style>
