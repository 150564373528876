var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tools02-report-export"},[_c('el-row',{staticStyle:{"margin":"0"}},[_c('el-col',{staticClass:"sub-title"},[_c('div',{staticStyle:{"margin-top":"65px"}},[(
            _vm.tool.codeKebab === 'google-cv' ||
            _vm.tool.codeKebab === 'yahoo-cv' ||
            _vm.tool.codeKebab === 'microsoft-cv'
          )?_c('h3',[_vm._v(" Step3："+_vm._s(_vm.tool.mediaName)+"のアップロード形式に変換したASP成果データのエクスポート ")]):(
            _vm.tool.codeKebab === 'facebook-cv' ||
            _vm.tool.codeKebab === 'tiktok-cv' ||
            _vm.tool.codeKebab === 'line-cv' ||
            _vm.tool.codeKebab === 'yda-cv'
          )?_c('h3',[_c('span',[_vm._v("Step3：")]),(_vm.tool.codeKebab === 'line-cv')?_c('span',[_vm._v("LINE広告")]):_c('span',[_vm._v(_vm._s(_vm.tool.mediaName))]),_c('span',[_vm._v("のレポートを検索")])]):_vm._e()]),_c('a',{attrs:{"href":_vm.toolData.parameterPassingType === '0'
            ? _vm.toolData.manualData.mediaUploadFileExportManualUrl
            : _vm.toolData.manualData.howToCheckImportedReportManualUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('p',{staticClass:"manual"},[_vm._v("【マニュアル】")])])])],1),_c('el-row',[_c('el-col',[_c('p',{staticClass:"date"},[_vm._v(" データの保存期間は過去60日分です。過去60日分までエクスポート可能です。 ")])])],1),_c('el-row',[_c('el-col',{staticClass:"today"},[_c('el-button',{staticClass:"today-bt",on:{"click":function($event){return _vm.getDateRange(1)}}},[_vm._v("今日")]),_c('el-button',{staticClass:"today-bt",on:{"click":function($event){return _vm.getDateRange(2)}}},[_vm._v("昨日")]),_c('el-button',{staticClass:"today-bt",on:{"click":function($event){return _vm.getDateRange(3)}}},[_vm._v("今日・昨日")]),_c('el-button',{staticClass:"today-bt",on:{"click":function($event){return _vm.getDateRange(4)}}},[_vm._v("今月")]),_c('el-button',{staticClass:"today-bt",on:{"click":function($event){return _vm.getDateRange(5)}}},[_vm._v("先月")]),_c('el-button',{staticClass:"today-bt",on:{"click":function($event){return _vm.getDateRange(0)}}},[_vm._v("全期間")])],1)],1),_c('el-row',[_c('el-col',[_c('el-date-picker',{attrs:{"type":"daterange","align":"right","start-placeholder":"Start Date","end-placeholder":"End Date","value-format":"yyyy-MM-dd"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1),(
      _vm.tool.codeKebab === 'google-cv' ||
      _vm.tool.codeKebab === 'yahoo-cv' ||
      _vm.tool.codeKebab === 'microsoft-cv' ||
      _vm.tool.codeKebab === 'yda-cv'
    )?_c('el-row',[_c('el-col',[_c('div',{staticClass:"el-select-prepend-cover"},[_c('div',{staticClass:"el-select-prepend"},[_vm._v("アカウント")]),_c('el-select',{attrs:{"placeholder":"全アカウント"},model:{value:(_vm.accountId),callback:function ($$v) {_vm.accountId=$$v},expression:"accountId"}},[_c('el-option',{key:0,staticClass:"select",attrs:{"label":`全アカウント`,"value":''}}),_vm._l((_vm.accounts),function(account){return _c('el-option',{key:account.accountId,attrs:{"label":account.accountName,"value":account.accountId}})})],2)],1)])],1):_vm._e(),_c('el-row',[_c('el-col',[_c('div',{staticClass:"el-select-prepend-cover"},[_c('div',{staticClass:"el-select-prepend"},[_vm._v("ASP")]),_c('el-select',{attrs:{"placeholder":"選択してください。"},model:{value:(_vm.aspId),callback:function ($$v) {_vm.aspId=$$v},expression:"aspId"}},[_c('el-option',{key:0,staticClass:"select",attrs:{"label":`全ASP`,"value":''}}),_vm._l((_vm.supportingImportAspList),function(asp){return _c('el-option',{key:asp.aspId,attrs:{"label":asp.aspName,"value":asp.aspId}})})],2)],1)])],1),_c('el-row',[_c('el-col',{staticStyle:{"text-align":"center"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getReportList('default', 1)}}},[_vm._v("検索")])],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalCount > 0),expression:"totalCount > 0"}],staticStyle:{"text-align":"center","margin-top":"15px"}},[(
          _vm.tool.codeKebab === 'google-cv' ||
          _vm.tool.codeKebab === 'yahoo-cv' ||
          _vm.tool.codeKebab === 'microsoft-cv' ||
          _vm.tool.codeKebab === 'yda-cv'
        )?_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.getReportList('csv', 0)}}},[_vm._v("エクスポート")]):_vm._e()],1)],1),_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalCount > 0),expression:"totalCount > 0"}]},[_c('el-col',[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('el-pagination',{attrs:{"page-size":_vm.pageSize,"pager-count":_vm.pagerCount,"total":_vm.totalCount,"current-page":_vm.currentPage,"layout":"prev, pager, next"},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}}),_c('div',[_vm._v("全 "+_vm._s(_vm._f("localeNum")(_vm.totalCount))+" 件")])],1),_c('table',{staticClass:"total_tbl"},[_c('thead',[_c('tr',[(_vm.tool.codeKebab === 'google-cv')?_c('th',[_vm._v("クリックID")]):(_vm.tool.codeKebab === 'facebook-cv')?_c('th',[_vm._v("FBCLID")]):(_vm.tool.codeKebab === 'yahoo-cv')?_c('th',[_vm._v("YCLID")]):(_vm.tool.codeKebab === 'tiktok-cv')?_c('th',[_vm._v("ttclid")]):(_vm.tool.codeKebab === 'microsoft-cv')?_c('th',[_vm._v("MSCLKID")]):(_vm.tool.codeKebab === 'line-cv')?_c('th',[_vm._v("LDTAGCL")]):(_vm.tool.codeKebab === 'yda-cv')?_c('th',[_vm._v("YCLID")]):_vm._e(),(_vm.tool.codeKebab === 'google-cv')?_c('th',[_vm._v("種別")]):_vm._e(),(
                _vm.tool.codeKebab === 'google-cv' ||
                _vm.tool.codeKebab === 'yahoo-cv' ||
                _vm.tool.codeKebab === 'microsoft-cv'
              )?_c('th',[_vm._v(" オフラインCV名 ")]):(
                _vm.tool.codeKebab === 'facebook-cv' ||
                _vm.tool.codeKebab === 'tiktok-cv' ||
                _vm.tool.codeKebab === 'line-cv' ||
                _vm.tool.codeKebab === 'yda-cv'
              )?_c('th',[_vm._v(" プログラム名 ")]):_vm._e(),_c('th',[_vm._v("CV時間")]),(
                _vm.tool.codeKebab === 'facebook-cv' ||
                _vm.tool.codeKebab === 'tiktok-cv' ||
                _vm.tool.codeKebab === 'line-cv'
              )?_c('th',[_vm._v(" CVページURL ")]):_vm._e(),_c('th',[_vm._v("報酬単価")]),_c('th',[_vm._v("承認率")]),_c('th',[_vm._v("報酬")]),(
                _vm.tool.codeKebab === 'google-cv' ||
                _vm.tool.codeKebab === 'yahoo-cv' ||
                _vm.tool.codeKebab === 'microsoft-cv'
              )?_c('th',[_vm._v(" アカウント名 ")]):_vm._e(),_c('th')])]),_c('tbody',_vm._l((_vm.reportList),function(reportData,reportIndex){return _c('tr',{key:reportIndex},[_c('td',[_c('div',{staticStyle:{"width":"250px","word-break":"break-all","margin":"0 auto"}},[_vm._v(" "+_vm._s(reportData.gclid)+" ")])]),(_vm.tool.codeKebab === 'google-cv')?_c('td',[(reportData.clickIdType === '0')?_c('div',[_vm._v("gclid")]):(reportData.clickIdType === '1')?_c('div',[_vm._v("wbraid")]):(reportData.clickIdType === '2')?_c('div',[_vm._v("gbraid")]):_vm._e()]):_vm._e(),(
                _vm.tool.codeKebab === 'google-cv' ||
                _vm.tool.codeKebab === 'yahoo-cv' ||
                _vm.tool.codeKebab === 'microsoft-cv'
              )?_c('td',[_c('div',[_vm._v(_vm._s(reportData.promotionData.cvName))])]):(
                _vm.tool.codeKebab === 'facebook-cv' ||
                _vm.tool.codeKebab === 'tiktok-cv' ||
                _vm.tool.codeKebab === 'line-cv' ||
                _vm.tool.codeKebab === 'yda-cv'
              )?_c('td',[_c('div',[_vm._v(_vm._s(reportData.promotionData.promotionName))])]):_vm._e(),_c('td',[_c('div',[_vm._v(_vm._s(reportData.formattedConversionTime))])]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.tool.codeKebab === 'facebook-cv' ||
                _vm.tool.codeKebab === 'tiktok-cv' ||
                _vm.tool.codeKebab === 'line-cv'
              ),expression:"\n                tool.codeKebab === 'facebook-cv' ||\n                tool.codeKebab === 'tiktok-cv' ||\n                tool.codeKebab === 'line-cv'\n              "}]},[_c('div',[_vm._v(_vm._s(reportData.promotionData.cvPageUrl))])]),_c('td',[_c('div',[_vm._v(_vm._s(_vm._f("localeNum")(reportData.conversionValue)))])]),_c('td',[_c('div',[_vm._v(_vm._s(reportData.approvalRate)+"%")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm._f("localeNum")(Math.floor( (reportData.conversionValue * reportData.approvalRate) / 100 )))+" ")])]),(
                _vm.tool.codeKebab === 'google-cv' ||
                _vm.tool.codeKebab === 'yahoo-cv' ||
                _vm.tool.codeKebab === 'microsoft-cv'
              )?_c('td',[_c('div',[_vm._v(_vm._s(reportData.accountData.accountName))])]):_vm._e(),_c('td',[_c('div',[_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.delReport(
                      reportData.reportId,
                      reportData.cvName,
                      reportData.conversionTime
                    )}}},[_vm._v("削除")])],1)])])}),0)]),_c('el-pagination',{attrs:{"page-size":_vm.pageSize,"pager-count":_vm.pagerCount,"total":_vm.totalCount,"current-page":_vm.currentPage,"layout":"prev, pager, next"},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1),(
      _vm.tool.codeKebab === 'google-cv' ||
      _vm.tool.codeKebab === 'yahoo-cv' ||
      _vm.tool.codeKebab === 'microsoft-cv'
    )?_c('el-row',{staticClass:"last-step"},[_c('el-col',{staticClass:"sub-title"},[_c('h3',[_vm._v("Step4："+_vm._s(_vm.tool.mediaName)+"へASP成果データをアップロード")]),_c('a',{attrs:{"href":_vm.toolData.manualData.mediaUploadManualUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('p',{staticClass:"manual"},[_vm._v("【マニュアル】")])])]),_c('el-col',[_c('p',{staticClass:"description"},[_vm._v(" アドタスカルからエクスポートしたファイルを"+_vm._s(_vm.tool.mediaName)+"にアップロードしてください。 ")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }