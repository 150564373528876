<template>
  <div class="ctrl-value-commercers">
    <el-row>
      <el-col>
        <h2>バリューコマース公開設定</h2>
      </el-col>
    </el-row>
    <ValueCommercerList />
  </div>
</template>

<script>
import ValueCommercerList from "@/components/Ctrl/ValueCommercers/ValueCommercerList.vue";

export default {
  data() {
    return {
      //
    };
  },
  components: {
    ValueCommercerList,
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
