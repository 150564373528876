<template>
  <div class="tools02-point-back-history-list">
    <!-- ------------------- ↓↓ 検索部分 ↓↓ ------------------- -->
    <div class="log-search-box">
      <el-row type="flex" align="middle">
        <el-col>
          <el-button
            @click="
              searchConditionData.conversionDateTimeRange =
                g_getDatetimeRange(60)
            "
            type="primary"
            >全件（過去６０日）</el-button
          >
          <el-button
            @click="
              searchConditionData.conversionDateTimeRange =
                g_getDatetimeRange('thisMonth')
            "
            type="primary"
            >今月</el-button
          >
          <el-button
            @click="
              searchConditionData.conversionDateTimeRange =
                g_getDatetimeRange('today')
            "
            type="primary"
            >本日</el-button
          >
          <el-button
            @click="
              searchConditionData.conversionDateTimeRange =
                g_getDatetimeRange('yesterday')
            "
            type="primary"
            >昨日</el-button
          >
        </el-col>
      </el-row>

      <el-row v-if="type === 'ctrl'" type="flex" align="middle">
        <el-col :span="3">
          <span>ユーザーID：</span>
        </el-col>
        <el-col :span="3">
          <el-input-number
            v-model="searchConditionData.userId"
            :min="0"
          ></el-input-number>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="5" :sm="3">
          <span>CV日時：</span>
        </el-col>
        <el-col :xs="19" :sm="9">
          <el-date-picker
            v-model="searchConditionData.conversionDateTimeRange"
            type="datetimerange"
            align="right"
            start-placeholder="開始日時"
            end-placeholder="終了日"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="9" :sm="3">
          <span>媒体：</span>
        </el-col>
        <el-col :xs="15" :sm="4">
          <el-select
            v-model="searchConditionData.toolId"
            placeholder="選択してください。"
            clearable
          >
            <el-option
              v-for="tool in sbExcludedToolListInTopPageOrder"
              :key="tool.toolId"
              :label="tool.mediaName"
              :value="tool.toolId"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="9" :sm="3">
          <span>ASP：</span>
        </el-col>
        <el-col :xs="15" :sm="5">
          <el-select
            v-model="searchConditionData.aspId"
            placeholder="選択してください。"
            clearable
          >
            <el-option
              v-for="asp in similarAspGroupedAspList"
              :key="asp.aspId"
              :label="asp.aspName"
              :value="asp.aspId"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="9" :sm="3">
          <span>プログラム名：</span>
        </el-col>
        <el-col :xs="15" :sm="10">
          <el-input
            placeholder="空白で検索対象外となります"
            v-model="searchConditionData.promotionName"
            clearable
          ></el-input>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="9" :sm="3">
          <span>パラメータ：</span>
        </el-col>
        <el-col :xs="15" :sm="21">
          <el-input
            placeholder="空白で検索対象外となります"
            v-model="searchConditionData.parameter"
            clearable
          ></el-input>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="9" :sm="3">
          <span>プログラムID：</span>
        </el-col>
        <el-col :xs="15" :sm="6">
          <el-input
            placeholder="空白で検索対象外となります"
            v-model="searchConditionData.aspPromotionName"
            clearable
          ></el-input>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="9" :sm="3">
          <span>インポート状況：</span>
        </el-col>
        <el-col :xs="15" :sm="9">
          <el-select
            v-model="searchConditionData.resultCode"
            placeholder="選択してください。"
            clearable
          >
            <el-option
              v-for="(
                importErrorTypeData, index
              ) in searchImportResultDefinitionList"
              :key="index"
              :label="importErrorTypeData.name"
              :value="importErrorTypeData.code"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col style="text-align: center">
          <el-button
            @click="setPointBackHistoryList('default', 1)"
            type="primary"
            >検索</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row type="flex" v-if="searchedConversionDatetimeRange.length > 0">
        <el-col :span="2">検索期間：</el-col>
        <el-col>
          {{ searchedConversionDatetimeRange[0] }} ～
          {{ searchedConversionDatetimeRange[1] }}
        </el-col>
      </el-row>
    </div>
    <!-- ------------------- ↑↑ 検索部分ここまで ↑↑ ------------------- -->

    <div
      v-if="
        searchConditionData.resultCode === '202' &&
        searchedConditionData !== null &&
        searchedConditionData.resultCode === '202' &&
        totalCount > 0
      "
      v-loading="reimporting === true"
      style="text-align: center"
    >
      <el-button @click="reimportInBatches" type="success"
        >重複成果を一括インポートする</el-button
      >
    </div>

    <div
      v-if="loading !== loaded"
      v-loading="loading !== loaded"
      style="width: 100%; height: 150px"
    ></div>
    <div v-else class="width-max-cover">
      <p style="margin-right: 10px; text-align: right">
        報酬額（本日）：{{ todayTotalRewards | localeNum }}
      </p>
      <p style="margin-right: 10px; text-align: right">
        報酬額（検索結果合計）：{{ searchResultTotalRewards | localeNum }}
      </p>
      <div class="csv-download-button">
        全 {{ totalCount | localeNum }} 件
        <el-button
          @click="setPointBackHistoryList('csv', 1)"
          type="success"
          style="margin-left: 1em"
          v-loading="downloading"
        >
          CSVダウンロード&nbsp;
          <!-- <el-tooltip
            class="item"
            effect="dark"
            :content="'データの保存期間は過去60日分のため、それ以前のデータはダウンロードできません。'"
            placement="top"
          >
            <i class="el-icon-question"></i>
          </el-tooltip> -->
        </el-button>
      </div>
      <el-row>
        <el-col>
          <div class="paging-cover-top">
            <el-pagination
              :page-size="pageSize"
              :pager-count="pagerCount"
              :total="totalCount"
              :current-page.sync="currentPage"
              layout="prev, pager, next"
            />
            <div class="error-description">
              エラー名をクリックするとエラーの原因と対処法を確認できます。
            </div>
          </div>
          <el-table
            v-loading="reimporting === true"
            :data="formattedPointBackHistoryList"
            border
            class="point-back-history-list"
          >
            <el-table-column
              v-if="type === 'ctrl'"
              prop="userId"
              label="userId"
              width="70px"
            >
            </el-table-column>
            <el-table-column prop="mediaAlphaCode" label="媒体" width="50px">
            </el-table-column>
            <el-table-column prop="aspName" label="ASP" width="90px">
            </el-table-column>
            <el-table-column prop="conversionTime" label="CV時間" width="110px">
            </el-table-column>
            <el-table-column
              prop="promotionName"
              label="プログラム名"
              width="110px"
            >
            </el-table-column>
            <el-table-column
              prop="parameter"
              label="パラメータ"
              min-width="400px"
            >
            </el-table-column>
            <el-table-column
              prop="aspPromotionName"
              label="プログラムID"
              width="200px"
            >
            </el-table-column>
            <el-table-column label="報酬額" width="100px">
              <template slot-scope="scope">
                <span>{{ scope.row.conversionValue | localeNum }}</span>
              </template>
            </el-table-column>
            <el-table-column label="実質報酬額（税抜）" width="100px">
              <template slot-scope="scope">
                <span>{{ scope.row.realConversionValue | localeNum }}</span>
              </template>
            </el-table-column>
            <el-table-column label="インポート状況" width="225px">
              <template
                slot-scope="scope"
                v-if="scope.row.resultData !== undefined"
              >
                <el-button
                  v-if="scope.row.resultData.code === '201'"
                  type="success"
                  icon="el-icon-check"
                  circle
                  class="success-icon"
                />
                <el-button v-else type="danger" round class="danger-icon"
                  >!</el-button
                >
                <a
                  :href="scope.row.resultData.manualUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                  :class="{ error: scope.row.resultData.code !== '201' }"
                  >{{ scope.row.resultData.name }}</a
                >
                <el-button
                  v-if="scope.row.resultData.code === '202'"
                  @click="reimportOnePointBack(scope.row)"
                  type="primary"
                  size="mini"
                  >重複成果でない場合はクリック</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :pager-count="pagerCount"
            :total="totalCount"
            :current-page.sync="currentPage"
            layout="prev, pager, next"
          />
        </el-col>
      </el-row>
    </div>
    <FAQ :faqList="faqList" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FAQ from "@/components/Ui/FAQ.vue";

export default {
  name: "PointBackHistoryList",
  components: {
    FAQ,
  },
  props: {
    type: String,
  },
  data() {
    return {
      reimporting: false,
      loading: null,
      loaded: null,
      downloading: false,
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,
      totalCount: 0,
      faqList: [
        {
          title: "キャンペーンにCVが反映しません。",
          url: "https://adtasukaru.wraptas.site/465c91d2f7c84647a055f158af705624",
        },
        {
          title: "クリックパラメータが取得できなかったときのリカバリー方法",
          url: "https://adtasukaru.wraptas.site/412f440e5fe040e583fb8a58d167040f",
        },
      ],

      // 検索条件
      searchConditionData: {
        conversionDateTimeRange: this.g_getDatetimeRange("today"),
        userId: "0",
        toolId: "",
        aspId: "",
        promotionName: "",
        parameter: "",
        aspPromotionName: "",
        resultCode: "",
      },

      // 検索結果
      pointBackHistoryList: [],
      searchResultTotalRewards: 0,
      todayTotalRewards: 0,
      searchedConditionData: null,
      searchedConversionDatetimeRange: [],
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "apiErrorMessage",
      "similarAspGroupedAspList",
      "aspNames",
      "userData",
    ]),
    ...mapGetters("tools02", [
      "sbExcludedToolListInTopPageOrder",
      "importResultDefinitionList",
    ]),
    searchImportResultDefinitionList() {
      let importResultDefinitionList = JSON.parse(
        JSON.stringify(this.importResultDefinitionList)
      );

      //
      return importResultDefinitionList.filter(
        (importResultDefinitionData) => importResultDefinitionData.code !== "0"
      );
    },
    formattedPointBackHistoryList() {
      const formattedPointBackHistoryList = [];
      for (const pointBackHistoryData of this.pointBackHistoryList) {
        //
        const toolData = this.$store.getters.toolData(
          pointBackHistoryData.toolId
        );
        let aspName = "-";
        if (this.$store.getters.aspDataFromAllAspList(pointBackHistoryData.aspId) !== undefined) {
          // 
          const aspData = this.$store.getters.aspDataFromAllAspList(pointBackHistoryData.aspId);

          // 
          aspName = aspData.aspName;
        } else {
          // no action.
        }
        const promotionData = this.$store.getters["tools02/promotionData"](
          pointBackHistoryData.promotionId
        );

        //
        let promotionName = "-";
        if (promotionData !== undefined) {
          promotionName = promotionData.promotionName;
        } else {
          // no action.
        }

        //
        const resultData = this.$store.getters[
          "tools02/importResultDefinitionData"
        ](pointBackHistoryData.resultCode);
        if (pointBackHistoryData.resultCode === '400') {
          resultData.manualUrl = `https://adtasukaru.wraptas.site/39940c1dce8e4f0696f31b5e15ec4817`;
        } else {
          // no action.
        }

        //
        const formattedPointBackHistoryData = {
          id: pointBackHistoryData.id,
          userId: pointBackHistoryData.userId,
          mediaAlphaCode: toolData.alphaCode,
          aspName: aspName,
          conversionTime: pointBackHistoryData.formattedConversionTime,
          promotionName: promotionName,
          parameter: pointBackHistoryData.parameter,
          aspPromotionName: pointBackHistoryData.aspPromotionName,
          conversionValue: pointBackHistoryData.conversionValue,
          realConversionValue: pointBackHistoryData.realConversionValue,
          resultData: resultData,
        };
        formattedPointBackHistoryList.push(formattedPointBackHistoryData);
      }
      return formattedPointBackHistoryList;
    },
  },
  watch: {
    currentPage: {
      handler(newValue) {
        //
        this.setPointBackHistoryList("default", newValue);
      },
      immediate: true,
    },
  },
  methods: {
    setPointBackHistoryList(mode, currentPage) {
      //
      if (mode === "default") {
        var loadingToken = this.g_generateRandomPassword(4); // axiosの中まで貫きたいのでvarにする
        this.loading = loadingToken;
      } else if (mode === "csv") {
        this.downloading = true;
      } else {
        return;
      }

      //
      this.getPointBackHistoryList(mode, currentPage)
        .then((response) => {
          if (response.data.status === 200) {
            if (mode === "default") {
              this.pointBackHistoryList = response.data.list;
              this.totalCount = response.data.totalCount;
              this.searchResultTotalRewards =
                response.data.searchResultTotalRewards;
              this.todayTotalRewards = response.data.todayTotalRewards;
              this.searchedConversionDatetimeRange =
                response.data.searchedConversionDatetimeRange;
              this.searchedConditionData = JSON.parse(
                JSON.stringify(this.searchConditionData)
              );
            } else if (mode === "csv") {
              location.href = `${this.rt}/l/api/file/download`;
            } else {
              console.log("???");
            }
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }

          //
          if (mode === "default") {
            this.loaded = loadingToken;
          } else if (mode === "csv") {
            this.downloading = false;
          } else {
            // no action.
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPointBackHistoryList(mode, currentPage) {
      return new Promise((resolve, reject) => {
        //
        const conversionDatetimeRange = JSON.stringify(
          this.searchConditionData.conversionDateTimeRange
        );

        //
        const userId =
          this.searchConditionData.userId === ""
            ? "0"
            : this.searchConditionData.userId;

        //
        const toolId =
          this.searchConditionData.toolId === ""
            ? "0"
            : this.searchConditionData.toolId;

        //
        const aspId =
          this.searchConditionData.aspId === ""
            ? "0"
            : this.searchConditionData.aspId;

        //
        const resultCode =
          this.searchConditionData.resultCode === ""
            ? "0"
            : this.searchConditionData.resultCode;

        //
        let parameters = "";
        parameters += `?type=${this.type}`;
        parameters += `&mode=${mode}`;
        parameters += `&conversion_datetime_range=${conversionDatetimeRange}`;
        parameters += `&user_id=${userId}`;
        parameters += `&tool_id=${toolId}`;
        parameters += `&asp_id=${aspId}`;
        parameters += `&promotion_name=${this.searchConditionData.promotionName}`;
        parameters += `&parameter=${this.searchConditionData.parameter}`;
        parameters += `&asp_promotion_name=${encodeURIComponent(
          this.searchConditionData.aspPromotionName
        )}`;
        parameters += `&result_code=${resultCode}`;
        parameters += `&page_size=${this.pageSize}`;
        parameters += `&current_page=${currentPage}`;
        this.$axios
          .get(
            `${this.rt}/l/api/tools02/point-back-history/get-list${parameters}`
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    reimportOnePointBack(pointBackHistoryData) {
      //
      let message = `以下の成果は重複成果でないため、インポートしますか？\n`;
      message += `\n`;
      message += `媒体: ${pointBackHistoryData.mediaAlphaCode}\n`;
      message += `成果日時: ${pointBackHistoryData.conversionTime}\n`;
      message += `プログラム名: ${pointBackHistoryData.promotionName}\n`;
      message += `ASP: ${pointBackHistoryData.aspName}\n`;
      message += `パラメータ: ${pointBackHistoryData.parameter}\n`;

      //
      if (confirm(message)) {
        this.reimporting = true;
      } else {
        return;
      }

      //
      let params = new URLSearchParams();
      params.append(
        "point_back_history_id_list",
        JSON.stringify([pointBackHistoryData.id])
      );
      this.$axios
        .post(`${this.rt}/l/api/tools02/import/reimport-point-back`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            if (response.data.resultList[0].status === 200) {
              alert(response.data.resultList[0].message);
              this.setPointBackHistoryList("default", 1);
            } else {
              alert(response.data.resultList[0].message);
            }
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.reimporting = false;
          return;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    reimportInBatches() {
      //
      if (confirm("現在、表示されている重複成果を全てインポートしますか？")) {
        this.reimporting = true;
      } else {
        return;
      }

      //
      this.getPointBackHistoryList("reimport", 1)
        .then((response) => {
          if (response.data.status === 200) {
            const pointBackHistoryIdList = response.data.list.map(
              (data) => data.id
            );

            //
            let params = new URLSearchParams();
            params.append(
              "point_back_history_id_list",
              JSON.stringify(pointBackHistoryIdList)
            );
            this.$axios
              .post(
                `${this.rt}/l/api/tools02/import/reimport-point-back`,
                params
              )
              .then((response) => {
                console.log(response);
                if (response.data.status === 200) {
                  alert("処理しました。");
                  this.setPointBackHistoryList("default", 1);
                } else if (response.data.status === 400) {
                  alert(response.data.message);
                } else if (response.data.status === 401) {
                  alert(response.data.message);
                  this.$router.push("/login");
                } else {
                  alert(this.apiErrorMessage);
                }
                this.reimporting = false;
                return;
              })
              .catch((error) => {
                console.log(error);
                this.reimporting = false;
                if (error.code === "ERR_NETWORK") {
                  alert("処理がタイムアウトしました。");
                } else {
                  alert(this.apiErrorMessage);
                }
                return;
              });
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.log-search-box {
  .el-date-editor,
  .el-input,
  .el-select {
    width: 100%;
  }
}
.width-max-cover {
  .csv-download-button {
    text-align: right;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .paging-cover-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .error-description {
      color: red;
      font-size: 14px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  .point-back-history-list {
    a.error {
      color: rgba(51, 126, 169, 1);
    }
  }
}
</style>
