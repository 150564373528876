<template>
  <div class="manual-google-cv-contents">
    <h2>ピクセルIDとアクセストークンの登録</h2>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>ビジネスマネージャで取得したピクセルIDとアクセストークンをアドタスカルの管理画面に登録します。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
        <h4>【１】<router-link :to="`/tools02/${tool.codeKebab}`">Meta広告コンバージョンAPI連携ツール</router-link>のトップページから、利用設定をONにします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no08/01.png`" alt="" />
        <h4>【２】「ピクセル登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no08/02.png`" alt="" />
        <h4>【３】「新規追加」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no08/03.png`" alt="" />
        <h4>【４】登録したピクセル名、取得したアクセストークンとピクセルIDを入力し、「登録」をクリックします（アクセストークンとピクセルIDの取得方法は、「<router-link :to="`/manual/${tool.codeKebab}/03/07`">ピクセルIDとアクセストークンの取得方法</router-link>」を参照）。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no08/04.png`" alt="" />
        <h4>【５】ピクセル情報が登録されました。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no08/05.png`" alt="" />
        <p>以上で、ピクセルIDとアクセストークンの登録は完了です。</p>
        <p>「<router-link :to="`/manual/${tool.codeKebab}/03/09`">ピクセルテスト</router-link>」に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
