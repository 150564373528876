<template>
  <div class="tools02-parameter-log-log-table">
    <!-- 検索部分 -->
    <div class="log-search-box">
      <el-row type="flex" align="middle">
        <el-col>
          <el-button @click="changeDatetimeRange('180')" type="primary">全件（過去１８０日）</el-button>
          <el-button @click="changeDatetimeRange('today')" type="primary">本日</el-button>
          <el-button @click="changeDatetimeRange('yesterday')" type="primary">昨日</el-button>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">
          <span>検索日時：</span>
        </el-col>
        <el-col :span="21">
          <el-date-picker v-model="datetimeRange" type="datetimerange" align="right" start-placeholder="開始日時"
            end-placeholder="終了日" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"></el-date-picker>
        </el-col>
      </el-row>

      <el-row v-if="pageType === 'ctrl'" type="flex" align="middle">
        <el-col :span="3"> ユーザーID： </el-col>
        <el-col :span="20">
          <el-input-number v-model="userId" clearable></el-input-number>
          <span>（０で検索対象外）</span>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="3">ピクセル名：</el-col>
        <el-col :span="10">
          <el-input v-model="pixelName" placeholder="空白で検索対象外となります" clearable></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">プログラム名：</el-col>
        <el-col :span="12">
          <el-input v-model="promotionName" placeholder="空白で検索対象外となります" clearable></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">atid：</el-col>
        <el-col :span="6">
          <el-input v-model="adtasukaruId" placeholder="空白で検索対象外となります" clearable></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">API送信結果：</el-col>
        <el-col :span="4">
          <el-select placeholder="選択してください" v-model="eventsReceived" clearable>
            <el-option v-for="item in eventsReceivedSelect" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col style="text-align: center">
          <el-button @click="getParameterLogs('default', 1)" type="primary">検索</el-button>
        </el-col>
      </el-row>
      <!--  -->
      <el-divider></el-divider>
      <el-row type="flex" v-if="searchedDatetimeRange.length > 0">
        <el-col :span="2">検索期間：</el-col>
        <el-col>
          {{ searchedDatetimeRange[0] }} ～
          {{ searchedDatetimeRange[1] }}
        </el-col>
      </el-row>
    </div>
    <!-- 検索部分ここまで -->

    <div v-if="loading === true" v-loading="loading" style="width: 100%; height: 150px"></div>
    <div v-else class="width-max-cover">
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
        <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
          :current-page.sync="currentPage" layout="prev, pager, next" />
        <div style="padding: 5px">
          <span style="margin-right: 15px">
            件数：
            <span>{{ totalCount }}</span>件
          </span>
          <el-button @click="getParameterLogs('csv', 1)" type="success">CSV</el-button>
        </div>
      </div>
      <el-table :data="apiHistoryList" border>
        <el-table-column fixed label="API送信時間" min-width="180px" prop="sendAt"></el-table-column>
        <el-table-column width="180px" label="プログラム名">
          <template slot-scope="scope">
            <span v-if="$store.getters['tools02/withTrashedPromotionData'](
            scope.row.reportData.promotionId
          ) !== undefined">
              {{
            $store.getters["tools02/withTrashedPromotionData"](
              scope.row.reportData.promotionId
            ).promotionName
          }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="150px" label="ユニット名">
          <template slot-scope="scope">
            <span v-if="$store.getters['tools02/unitDataByToken'](scope.row.reportData.unitToken) !== undefined">
              {{ $store.getters["tools02/unitDataByToken"](scope.row.reportData.unitToken).unitName }}
            </span>
            <span v-else>
              <span
                v-if="$store.getters['tools02/withTrashedFacebookUnitDataByToken'](scope.row.reportData.unitToken) !== undefined">
                <span
                  v-if="$store.getters['tools02/withTrashedFacebookUnitDataByToken'](scope.row.reportData.unitToken) !== undefined">
                  {{
            $store.getters["tools02/withTrashedFacebookUnitDataByToken"](scope.row.reportData.unitToken).unitName
          }}
                </span>
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="150px" label="ユニットトークン">
          <template slot-scope="scope">
            <span>{{ scope.row.reportData.unitToken }}</span>
          </template>
        </el-table-column>
        <el-table-column width="300px" label="ピクセル名">
          <template slot-scope="scope">
            <span v-if="$store.getters['tools02/withTrashedFacebookPixelDataByPixelIdForApi'](
            scope.row.pixelIdForApi
          ) !== undefined
            ">
              {{
            $store.getters["tools02/withTrashedFacebookPixelDataByPixelIdForApi"](
              scope.row.pixelIdForApi
            ).pixelName
          }}
            </span>
          </template>
        </el-table-column>
        <el-table-column min-width="200px" prop="pixelIdForApi" label="ピクセルID"></el-table-column>
        <el-table-column label="API送信結果">
          <template slot-scope="scope">
            <span v-if="scope.row.eventsReceived === true">成功</span>
            <span v-else>失敗</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120px" prop="errorSubcode" label="エラーコード">
        </el-table-column>
        <el-table-column width="300px" label="エラータイトル">
          <template slot-scope="scope">
            <el-input readonly v-model="scope.row.errorUserTitle">
              <el-button @click="copyText(scope.row.errorUserTitle)" slot="append"
                icon="el-icon-document-copy"></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column width="500px" label="エラーメッセージ">
          <template slot-scope="scope">
            <el-input readonly v-model="scope.row.errorUserMsg">
              <el-button @click="copyText(scope.row.errorUserMsg)" slot="append"
                icon="el-icon-document-copy"></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="200px" label="発生時間">
          <template slot-scope="scope">
            {{ scope.row.conversionDateTime }}
          </template>
        </el-table-column>
        <el-table-column min-width="100px" label="報酬額">
          <template slot-scope="scope">
            {{ scope.row.value | localeNum }}
          </template>
        </el-table-column>
        <el-table-column min-width="200px" label="CVイベント">
          <template slot-scope="scope">
            <span v-if="$store.getters['tools02/facebookEventDataByEventName'](scope.row.eventName) === undefined">
              <span v-if="scope.row.eventName === ''">-</span>
              <span v-else>カスタム（{{ scope.row.eventName }}） </span>
            </span>
            <span v-else>
              {{ $store.getters["tools02/facebookEventDataByEventName"](scope.row.eventName).displayName }} </span>
          </template>
        </el-table-column>
        <el-table-column min-width="300px" label="atid">
          <template slot-scope="scope">
            <el-input readonly v-model="scope.row.contentName">
              <el-button @click="copyText(scope.row.contentName)" slot="append"
                icon="el-icon-document-copy"></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="300px" label="fbclid">
          <template slot-scope="scope">
            <el-input readonly v-model="scope.row.reportData.gclid">
              <el-button @click="copyText(scope.row.reportData.gclid)" slot="append"
                icon="el-icon-document-copy"></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="300px" label="fbc">
          <template slot-scope="scope">
            <el-input readonly v-model="scope.row.fbc">
              <el-button @click="copyText(scope.row.fbc)" slot="append" icon="el-icon-document-copy"></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="300px" label="fbp">
          <template slot-scope="scope">
            <el-input readonly v-model="scope.row.fbp">
              <el-button @click="copyText(scope.row.fbp)" slot="append" icon="el-icon-document-copy"></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="300px" label="User Agent">
          <template slot-scope="scope">
            <el-input readonly v-model="scope.row.clientUserAgent">
              <el-button @click="copyText(scope.row.clientUserAgent)" slot="append"
                icon="el-icon-document-copy"></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="160px" prop="clientIpAddress" label="IPアドレス">
        </el-table-column>
        <el-table-column width="400px" label="CVページURL">
          <template slot-scope="scope">
            <span>{{ scope.row.eventSourceUrl === '' ? '-' : scope.row.eventSourceUrl }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="pageType === 'ctrl'" prop="pixelId" label="pixel_id">
        </el-table-column>
        <el-table-column min-width="250px" prop="fbtraceId" label="fbtrace_id">
        </el-table-column>
        <el-table-column v-if="pageType === 'ctrl'" prop="userId" label="ユーザーID">
        </el-table-column>
        <el-table-column fixed="right" min-width="80px" label="再送">
          <template slot-scope="scope">
            <el-button @click="resendFacebookConversionApi(scope.row)" type="primary" size="mini">再送</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
        :current-page.sync="currentPage" layout="prev, pager, next" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CtrlFacebookApiHistoryLogTable",
  components: {},
  props: {
    pageType: {
      validator(value) {
        return ["default", "ctrl"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      toolId: 0,
      apiHistoryList: [],
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,
      totalCount: 0,

      // ===== ↓↓ 検索用 ↓↓ =====
      userId: 0,
      pixelName: "",
      promotionName: "",
      adtasukaruId: "",
      datetimeRange: [],
      eventsReceived: "",
      // ===== ↑↑ 検索用 ↑↑ =====

      eventsReceivedSelect: [
        {
          value: "1",
          label: "成功",
        },
        {
          value: "0",
          label: "失敗",
        },
      ],
      searchedDatetimeRange: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "aspNames"]),
  },
  watch: {
    currentPage: {
      handler(newValue) {
        this.getParameterLogs("default", newValue);
      },
      immediate: true,
    },
  },
  methods: {
    changeDatetimeRange(term) {
      this.datetimeRange = this.g_getDatetimeRange(term);
    },
    getParameterLogs(getType, currentPage) {
      //
      this.currentPage = currentPage;
      this.loading = true;

      //
      let url = `${this.rt}/l/api/tools02/media/facebook/get-api-history-list`;
      url += `?page_type=${this.pageType}`;
      url += `&get_type=${getType}`;
      url += `&user_id=${this.userId}`;
      url += `&datetime_range=${JSON.stringify(this.datetimeRange)}`;
      url += `&pixel_name=${this.pixelName}`;
      url += `&promotion_name=${this.promotionName}`;
      url += `&adtasukaru_id=${this.adtasukaruId}`;
      url += `&events_received=${this.eventsReceived}`;
      url += `&current_page=${currentPage}`;
      url += `&page_size=${this.pageSize}`;
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            if (getType === "default") {
              this.apiHistoryList = response.data.list;
              this.totalCount = response.data.totalCount;
              this.searchedDatetimeRange = response.data.searchedDatetimeRange;
            } else if (getType === "csv") {
              location.href = `${this.rt}/l/api/file/download`;
            } else {
              console.log("???");
            }
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    resendFacebookConversionApi(historyData) {
      // 
      const promotionName = this.$store.getters['tools02/withTrashedPromotionData'](historyData.reportData.promotionId) !== undefined ? this.$store.getters["tools02/withTrashedPromotionData"](
        historyData.reportData.promotionId
      ).promotionName : '-';

      // 
      const pixelName = this.$store.getters['tools02/withTrashedFacebookPixelDataByPixelIdForApi'](
        historyData.pixelIdForApi
      ) !== undefined ? this.$store.getters['tools02/withTrashedFacebookPixelDataByPixelIdForApi'](
        historyData.pixelIdForApi
      ).pixelName : '-';


      //
      let confirmText = "";
      confirmText += `本当にこの成果をAPI再送信してもいいですか？\n`;
      confirmText += `プログラム名：${promotionName}\n`;
      confirmText += `ピクセル名：${pixelName}\n`;
      confirmText += `発生時間：${historyData.conversionDateTime}\n`;
      confirmText += `atid：${historyData.contentName}`;

      //
      if (confirm(confirmText)) {
        let params = new URLSearchParams();
        params.append("send_id", historyData.id);
        this.$axios
          .post(
            `${this.rt}/api/tools02/facebook-cv/resend-facebook-conversion-api.php`,
            params
          )
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              alert("送信しました。");
              return;
            } else if (response.data.status === 400) {
              alert("エラーが発生しました。");
              return;
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // no action.
      }
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
      alert("コピーしました。");
    },
  },
};
</script>

<style lang="scss" scoped></style>
