<template>
  <div class="tools02-promotion-detail-promotion-editor">
    <div class="cover">
      <div class="button">
        <a :href="toolData.manualData.promotionRegistrationManualUrl" target="_blank" rel="noopener noreferrer">
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </div>
      <div class="table-cover">
        <table class="typeB">
          <tr>
            <th>ASP</th>
            <td>
              <div class="link-cover">
                <div>
                  <el-select v-model="promotionData.aspId" style="width: 270px" placeholder="選択してください。">
                    <el-option v-for="asp in aspList" :key="asp.aspId" :label="asp.aspName"
                      :value="asp.aspId"></el-option>
                  </el-select>
                  <ValidationErrorMessages :errorMessageList="validationErrorData.aspId" />
                  <div v-if="promotionData.aspId === '7' || promotionData.aspId === '81'" class="caution">
                    ※「famAD」と「fam」は別ASP扱いになります。お間違いないように選択ください。<br>（見分け方は<a
                      href="https://adtasukaru.wraptas.site/942a07be92e94e90b61d393735986e04" target="_blank"
                      rel="noopener noreferrer">こちら</a>）</div>
                </div>
                <div v-if="promotionData.aspId !== ''">
                  <el-link class="link" v-show="aspManualUrls[promotionData.aspId].adminUrl != ''"
                    :href="aspManualUrls[promotionData.aspId].adminUrl" type="primary" target="_blank">{{
                      aspNames[promotionData.aspId] }}管理画面</el-link>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              プログラム名
              <Question :placement="'right'" :content="'アドタスカル管理用の名前です。ご自身で分かりやすい名前をつけてください。'" />
            </th>
            <td>
              <el-input v-model="promotionData.promotionName"></el-input>
              <ValidationErrorMessages :errorMessageList="validationErrorData.promotionName" />
            </td>
          </tr>
          <tr v-if="aspData.usesAspPromotionName === '1'">
            <th>
              ASPプログラム名
              <Question :placement="'right'" :content="'必ず、ASPで表示されている正式名称を入力してください（コピー＆ペーストしてください）。'" /><br /><span
                style="font-size: 10px">※コピー＆ペーストしてください。</span>
            </th>
            <td>
              <div class="link-cover">
                <div class="frame">
                  <el-input v-model="promotionData.aspPromotionName"></el-input>
                  <ValidationErrorMessages :errorMessageList="validationErrorData.aspPromotionName" />
                </div>
                <div v-if="promotionData.aspId !== ''" style="width: 5em">
                  <template v-if="
                    aspManualUrls[promotionData.aspId].aspPnameManual.match(
                      /(http|https):\/\/.+/
                    )
                  ">
                    <a :href="aspManualUrls[promotionData.aspId].aspPnameManual" v-show="aspManualUrls[promotionData.aspId].aspPnameManual != ''
                      " target="_blank" rel="noopener noreferrer" class="link">確認方法</a>
                  </template>
                  <template v-else>
                    <router-link v-show="aspManualUrls[promotionData.aspId].aspPnameManual != ''
                      " :to="aspManualUrls[promotionData.aspId].aspPnameManual" target="_blank"
                      rel="noopener noreferrer" class="link">確認方法</router-link>
                  </template>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="
            aspData.aspId === '1' ||
            aspData.usesAspPromotionId === '1'
          ">
            <th>
              プログラムID<br /><span style="font-size: 10px">※自動登録の際に使用します。</span>
            </th>
            <td>
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                <div>
                  <el-input v-model="promotionData.aspPromotionId"></el-input>
                  <ValidationErrorMessages :errorMessageList="validationErrorData.aspPromotionId" />
                </div>
                <span v-show="aspData.pointBackPromotionId === '0'"
                  style="font-size: 12px; font-weight: bold">※ご自身で命名してください<br />※半角英数字</span>
                <div v-if="promotionData.aspId !== ''">
                  <template v-if="
                    aspManualUrls[promotionData.aspId].pidManualUrl.match(
                      /(http|https):\/\/.+/
                    )
                  ">
                    <a :href="aspManualUrls[promotionData.aspId].pidManualUrl" v-show="aspManualUrls[promotionData.aspId].pidManualUrl != ''
                      " target="_blank" rel="noopener noreferrer" style="font-size: 1.25em">確認方法</a>
                  </template>
                  <template v-else>
                    <router-link :to="aspManualUrls[promotionData.aspId].pidManualUrl" v-show="aspManualUrls[promotionData.aspId].pidManualUrl != ''
                      " target="_blank" rel="noopener noreferrer" style="font-size: 1.25em">確認方法</router-link>
                  </template>
                </div>
              </div>
              <div v-show="aspData.pointBackPromotionId === '0'" class="how-to-use-promotion-id">
                <p @click="
                  toRegistrationManual(
                    promotionData.aspId,
                    aspManualUrls[promotionData.aspId].pidManualUrl
                  )
                  ">
                  <span>※決めたプログラムIDを</span>
                  <span v-if="promotionData.aspId === '102'">通知URL</span>
                  <span v-else>アフィリエイトリンク</span>
                  <span>に付与してください</span>
                </p>
                <!-- <template
                  v-if="
                    aspManualUrls[promotionData.aspId].pidManualUrl.match(
                      /(http|https):\/\/.+/
                    )
                  "
                >
                  <a
                    :href="aspManualUrls[promotionData.aspId].pidManualUrl"
                    style="
                      font-size: 12px;
                      border-bottom: 1px solid;
                      text-decoration: revert;
                    "
                    target="_blank"
                    rel="noopener noreferrer"
                    ></a
                  >
                </template>
                <template v-else>
                  <router-link
                    :to="aspManualUrls[promotionData.aspId].pidManualUrl"
                    style="
                      font-size: 12px;
                      border-bottom: 1px solid;
                      text-decoration: revert;
                    "
                    target="_blank"
                    rel="noopener noreferrer"
                    >※決めたプログラムIDをアフィリエイトリンクに付与してください</router-link
                  >
                </template> -->
              </div>
            </td>
          </tr>
          <template v-if="
            toolData.codeKebab === 'google-cv' ||
            toolData.codeKebab === 'yahoo-cv' ||
            toolData.codeKebab === 'microsoft-cv'
          ">
            <tr>
              <th>
                オフラインCV名<br />
                <span v-if="toolData.codeKebab === 'google-cv'" class="description">※Google広告のオフラインCV名</span>
                <span v-else-if="toolData.codeKebab === 'yahoo-cv'" class="description">※Yahoo!検索広告のコンバージョン名</span>
                <span v-if="toolData.codeKebab === 'microsoft-cv'" class="description">※Microsoft広告のオフラインCV名</span>
              </th>
              <td>
                <div class="link-cover">
                  <div class="frame">
                    <el-input v-model="promotionData.cvName"></el-input>
                    <ValidationErrorMessages :errorMessageList="validationErrorData.cvName" />
                  </div>
                  <div>
                    <a v-if="toolData.codeKebab === 'yahoo-cv'"
                      href="https://adtasukaru.wraptas.site/e8b0a1b683d34a0f884d360f2171e522" target="_blank"
                      rel="noopener noreferrer" class="link">
                      確認方法
                    </a>
                    <a v-else-if="toolData.codeKebab === 'microsoft-cv'"
                      href="https://adtasukaru.wraptas.site/6774c5a8c01544b4a2715b36cc1e2655" target="_blank"
                      rel="noopener noreferrer" class="link">
                      確認方法
                    </a>
                    <a v-else href="https://adtasukaru.wraptas.site/89ccc53d2e364595b840a77eafe71fa6" target="_blank"
                      rel="noopener noreferrer" class="link">確認方法</a>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-if="toolData.codeKebab === 'facebook-cv'">
            <tr>
              <th>CVイベント</th>
              <td>
                <div>
                  <el-select v-model="promotionData.facebookEventId" placeholder="選択してください。">
                    <el-option v-for="(facebookEventData, index) in facebookEventList" :key="index"
                      :label="facebookEventData.displayName" :value="facebookEventData.id"></el-option>
                  </el-select>
                  <ValidationErrorMessages :errorMessageList="validationErrorData.facebookEventId" />
                </div>
              </td>
            </tr>
            <tr v-show="promotionData.facebookEventId === '18'">
              <th>カスタムイベント</th>
              <td>
                <div style="width: 85%">
                  <el-input v-model="promotionData.facebookCustomEventName"></el-input>
                  <ValidationErrorMessages :errorMessageList="validationErrorData.facebookCustomEventName
                    " />
                </div>
              </td>
            </tr>
          </template>
          <tr v-if="toolData.codeKebab === 'tiktok-cv'">
            <th>CVイベント</th>
            <td>
              <div>
                <el-select v-model="promotionData.tiktokEventId" placeholder="選択してください。" style="width: 330px" clearable>
                  <el-option v-for="(tiktokEventData, index) in tiktokEventList" :key="index" :label="tiktokEventData.name + `（${tiktokEventData.event}）`
                    " :value="tiktokEventData.id"></el-option>
                </el-select>
                <ValidationErrorMessages :errorMessageList="validationErrorData.tiktokEventId" />
              </div>
            </td>
          </tr>
          <tr v-if="
            toolData.codeKebab === 'facebook-cv' ||
            toolData.codeKebab === 'tiktok-cv'
          ">
            <th>CVページURL</th>
            <td>
              <div style="width: 85%">
                <el-input v-model="promotionData.cvPageUrl"></el-input>
                <ValidationErrorMessages :errorMessageList="validationErrorData.cvPageUrl" />
              </div>
              <div v-if="toolData.codeKebab === 'facebook-cv'" style="font-size: 12px">
                ※「ドメイン認証」したドメインを使って、架空のURLを入力してください。
              </div>
            </td>
          </tr>
          <tr v-if="aspData.pointBackReward === '0'">
            <th>報酬額</th>
            <td>
              <el-input v-model="promotionData.reward" style="width: 150px; margin-right: 7px">
              </el-input>
              <span>円</span>
              <ValidationErrorMessages :errorMessageList="validationErrorData.reward" />
            </td>
          </tr>
          <tr>
            <th>
              承認率
              <Question :placement="'right'"
                :content="'「コンバージョン値」の計算に使います。<br>コンバージョン値＝報酬額×承認率<br>全承認、承認率保証がない場合は、過去のデータから承認率を計算してください。'" />
            </th>
            <td>
              <el-input v-model="promotionData.approvalRate" style="width: 90px">
              </el-input>
              <span>%</span>
              <ValidationErrorMessages :errorMessageList="validationErrorData.approvalRate" />
            </td>
          </tr>
          <tr v-if="
            toolData.parameterPassingType === '1' &&
            toolData.codeKebab !== 'facebook-cv'
          ">
            <th>
              <span v-if="toolData.codeKebab === 'tiktok-cv'">ピクセル</span>
              <span v-else-if="toolData.codeKebab === 'line-cv'">LINE Tag</span>
              <span v-else-if="toolData.codeKebab === 'yda-cv'">コンバージョン</span>
              <!-- <Question :placement="'right'" :content="'???<br>???<br>???'" /> -->
            </th>
            <td>
              <el-select v-model="promotionData.tools02TagIdList" multiple placeholder="選択してください" style="width: 75%">
                <el-option v-for="tagData in tagList" :key="tagData.id" :label="`${toolData.codeKebab === 'yda-cv'
                    ? tagData.tools02AccountData.accountName + ' | '
                    : ''
                  }${tagData.name}`" :value="tagData.id">
                </el-option>
              </el-select>
              <ValidationErrorMessages :errorMessageList="validationErrorData.tools02TagIdList" />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="regPromotionDetail" type="primary">登録</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";
import Question from "@/components/Ui/Question.vue";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";

export default {
  name: "Tools02PromotionDetailPromotionEditor",
  components: {
    Button2,
    Question,
    ValidationErrorMessages,
  },
  props: {
    toolId: String,
    accountId: String,
    promotionId: String,
  },
  data() {
    return {
      promotionData: {
        promotionId: "0",
        toolId: "",
        accountId: "0",
        aspId: "",
        promotionName: "",
        aspPromotionName: "",
        aspPromotionId: "",
        reward: 0,
        approvalRate: 100,
        cvName: "",
        cvId: "",
        cvPageUrl: "",
        facebookEventId: "",
        facebookCustomEventName: "",
        tiktokEventId: "",
        tools02TagIdList: [],
        tools02TagList: [],
      },
      aspData: {},

      //
      validationErrorData: {
        aspId: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "apiErrorMessage",
      "tools",
      "cartDiscardedAspList",
      "similarAspGroupedAspList",
      "aspNames",
      "aspManualUrls",
    ]),
    ...mapGetters("tools02", ["facebookEventList", "tiktokEventList"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
    tagList() {
      return this.$store.getters["tools02/allTagListInTool"](this.toolId);
    },
    aspList: function () {
      let newAspDataList = JSON.parse(
        JSON.stringify(this.similarAspGroupedAspList)
      );

      //
      if (this.promotionData.aspId === "") {
        // no action.
      } else {
        const aspIdList = newAspDataList.map((newAspData) => newAspData.aspId);
        if (aspIdList.includes(this.promotionData.aspId) === true) {
          // ok.
        } else {
          newAspDataList.push(
            this.cartDiscardedAspList.find(
              (aspData) => aspData.aspId === this.promotionData.aspId
            )
          );
        }
      }

      //
      return newAspDataList;
    },
  },
  watch: {
    promotionId: {
      handler(newValue) {
        if (newValue === "0") {
          // no action.
        } else {
          this.promotionData = JSON.parse(
            JSON.stringify(
              this.$store.getters["tools02/promotionData"](newValue)
            )
          );
        }
      },
      immediate: true,
    },
    "promotionData.aspId": {
      handler(newValue) {
        this.aspData = JSON.parse(
          JSON.stringify(this.$store.getters.aspData(newValue))
        );
      },
      immediate: true,
    },
  },
  created() {
    //
    setTimeout(function () {
      $(".el-select-dropdown__wrap").css("max-height", "500px");
    }, 500);

    // copy
    if (this.$route.query.copyPromotionId !== undefined) {
      //
      const copyPromotionData = this.$store.getters["tools02/promotionData"](
        this.$route.query.copyPromotionId
      );

      //
      this.promotionData.aspId = copyPromotionData.aspId;
      this.promotionData.promotionName = copyPromotionData.promotionName;
      this.promotionData.aspPromotionName = copyPromotionData.aspPromotionName;
      this.promotionData.aspPromotionId = copyPromotionData.aspPromotionId;
      this.promotionData.cvName = copyPromotionData.cvName;
      this.promotionData.reward = copyPromotionData.reward;
      this.promotionData.approvalRate = copyPromotionData.approvalRate;
      this.promotionData.cvPageUrl = copyPromotionData.cvPageUrl;

      //
      const hash = ".tools02-promotion-detail-promotion-editor";
      this.$nextTick(() => {
        this.$scrollTo(hash, 0, {});
      });
    } else {
      // no action.
    }
  },
  methods: {
    toRegistrationManual(aspId, manualUrl) {
      if (aspId === "102") {
        window.open(
          "https://adtasukaru.wraptas.site/53e07ea11ed146f19a4d765e0cc4443e",
          "_blank",
          "noopener noreferrer"
        );
      } else {
        if (manualUrl.match(/(http|https):\/\/.+/)) {
          window.open(manualUrl, "_blank", "noopener noreferrer");
        } else {
          this.$router.push(manualUrl);
        }
      }
    },
    regPromotionDetail() {
      let validationFlg = true;
      let alertMsg = "";

      if (
        !this.promotionData.aspPromotionName.match(
          /^(\x20|\u3000|\t)|(\x20|\u3000|\t)$/
        )
      ) {
        //
      } else {
        validationFlg = false;
        alertMsg +=
          "ASPプログラム名の先頭か末尾にスペースが入力されていますが間違いないですか？\n";
      }
      if (
        !this.promotionData.cvName.match(/^(\x20|\u3000|\t)|(\x20|\u3000|\t)$/)
      ) {
        //
      } else {
        validationFlg = false;
        alertMsg +=
          "CV名の先頭か末尾にスペースが入力されていますが間違いないですか？\n";
      }
      if (
        !this.promotionData.aspPromotionId.match(
          /^(\x20|\u3000|\t)|(\x20|\u3000|\t)$/
        )
      ) {
        //
      } else {
        validationFlg = false;
        alertMsg +=
          "プログラムIDの先頭か末尾にスペースが入力されていますが間違いないですか？\n";
      }

      if (validationFlg === true) {
        //
      } else {
        if (!window.confirm(alertMsg)) {
          return false;
        }
      }

      //
      const tiktokEventId =
        this.promotionData.tiktokEventId === ""
          ? "0"
          : this.promotionData.tiktokEventId;

      // let params = new URLSearchParams();
      // params.append("tool_id", this.tools[this.toolData.codeCamel].id);
      // params.append("account_id", this.accountId);
      // params.append("promotion_data", JSON.stringify(this.promotionData));
      // this.$axios
      //   .post(`${this.rt}/api/tools02/regPromotionDetail.php`, params)
      let params = new URLSearchParams();
      params.append("promotion_id", this.promotionId);
      params.append("tool_id", this.toolId);
      params.append("account_id", this.accountId);
      params.append("asp_id", this.promotionData.aspId);
      params.append("promotion_name", this.promotionData.promotionName);
      params.append("asp_promotion_name", this.promotionData.aspPromotionName);
      params.append("asp_promotion_id", this.promotionData.aspPromotionId);
      params.append("cv_name", this.promotionData.cvName);
      params.append("cv_id", this.promotionData.conversionId);
      params.append("reward", this.promotionData.reward);
      params.append("approval_rate", this.promotionData.approvalRate);
      params.append("facebook_event_id", this.promotionData.facebookEventId);
      params.append(
        "facebook_custom_event_name",
        this.promotionData.facebookCustomEventName
      );
      params.append("tiktok_event_id", tiktokEventId);
      params.append("cv_page_url", this.promotionData.cvPageUrl);
      params.append(
        "tools02_tag_id_list",
        JSON.stringify(this.promotionData.tools02TagIdList)
      );
      this.$axios
        .post(`${this.rt}/l/api/tools02/promotion/register`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("tools02/setWithTrashedPromotionList");
            this.$store.dispatch("setUserData");
            this.$router.go(-1);
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: var(--blue2);
  font-weight: bold;
  text-decoration: none;

  &:visited {
    color: var(--blue2);
  }
}

.description {
  font-size: 10px;
}

.cover {
  .button {
    width: 180px;
    margin-bottom: 10px;
  }

  .table-cover {
    display: flex;
    align-items: center;
    justify-content: center;

    .typeB {
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }

      tr {
        th {
          @media screen and (max-width: 768px) {
            padding: 5px;
            width: 35%;
          }
        }

        td {
          .how-to-use-promotion-id {
            margin-top: 5px;
            text-align: right;

            p {
              margin: 0;
              font-size: 12px;
              border-bottom: 1px solid;
              text-decoration: revert;
              font-weight: bold;
              display: inline-block;
              color: var(--blue2);
              cursor: pointer;
            }
          }

          .caution {
            color: red;
            font-size: 13px;
            margin-top: 0.5em;
          }
        }
      }
    }
  }
}

.link-cover {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.link {
  font-size: 1.25em;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.frame {
  width: 85%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
</style>
