import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

// 外部のstore
import affiliate from '@/store/modules/affiliate/index.js';
import ctrl from '@/store/modules/ctrl/index.js';
import subscription from '@/store/modules/subscription/index.js';
import tools01 from '@/store/modules/tools01/index.js';
import tools02 from '@/store/modules/tools02/index.js';

// consts
import Tools02CartSystemConsts from '@/consts/Tools02/CartSystemConsts'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    rootUrl: '',
    rt: '',
    login: 0,
    constData: {},
    userData: {},
    productList: [],
    activePlanData: {},
    stockPoint: 0,
    bonusPointList: [],
    spentAmountsThisMonthInEachTool: [],
    asps: [],
    uploadsFolder: '/uploads/user/active/',
    tool: {}, // 現在のページのtool情報
    // currentPageToolData: {}, // 現在のページのtool情報
    tools: {},
    toolList: [],
    lastVisitedToolCode: '',
    apMinValue: 1000,
    apMaxValue: 50000,
    registeredCardList: [],
    imgLimit: 500000,
    imgLimitMessage: "500KB以下",
    newsOpen: 1,
    backButton: 1,
    QAList: {},
    openSidebar: false,
    apiErrorMessage: "",
    bankAccountTypeList: [],

    tutorialList: [],
    importErrorTypeList: [],

    domainList: [],

    //mypage
    privilegeList: [],
    privilegeConditionList: [],

    //tools01(mistook...)
    formatEditFrom: '',
    copyPageTool: '',
    copyPageId: 0,
    formatList: [],
    ABTestDetailInfo: []
  },
  getters: {
    rootUrl(state) {
      return state.rootUrl
    },
    rt(state) {
      return state.rt
    },
    login(state) {
      return state.login
    },
    constData(state) {
      return state.constData
    },
    toolIdFromToolCode: (state) => (toolCodeKebab) => {
      let toolId = undefined;
      const toolData = state.toolList.find(toolData => toolData.codeKebab === toolCodeKebab);
      if (toolData !== undefined) {
        toolId = toolData.toolId;
      } else {
        // no action.
      }
      return toolId;
    },
    userData(state) {
      return state.userData
    },
    cartDiscardedAspList(state, getters) {
      // 
      let aspList = [];
      let cartSystemAspList = [];

      // 
      for (const aspData of state.asps) {

        // カートの絞り込み
        if (state.subscription.validities.cartSystem === 1) {
          // ok.
        } else {
          if (aspData.isCartSystem === '0') {
            // ok.
          } else {
            continue;
          }
        }

        // 
        if (aspData.isCartSystem === '1') {
          // 
          if (getters['tools02/activeCartSystemSubscriptionData'] !== undefined) {
            if (getters['tools02/activeCartSystemSubscriptionData'].cartSystemProductId === Tools02CartSystemConsts.PRODUCT_ID_SINGLE) {
              if (getters['tools02/activeCartSystemSubscriptionData'].aspId === aspData.aspId) {
                cartSystemAspList.push(aspData);
              } else {
                // no action.
              }
            } else if (getters['tools02/activeCartSystemSubscriptionData'].cartSystemProductId === Tools02CartSystemConsts.PRODUCT_ID_PREMIUM) {
              cartSystemAspList.push(aspData);
            } else {
              // no action.
            }
          } else {
            // no action.
          }

          // 
          continue;
        } else {
          // no action.
        }

        // 
        aspList.push(aspData);
      }

      // 
      aspList = aspList.concat(cartSystemAspList);

      // 
      return aspList;
    },
    aspList(state, getters) {
      let aspList = [];

      // 
      for (const aspData of getters.cartDiscardedAspList) {

        // ASP選択で絞り込み
        if (getters.userData.displayAllAsps === true) {
          // ok.
        } else {
          if (getters.userData.displayAspIdList.includes(aspData.aspId) === true) {
            // ok.
          } else {
            continue;
          }
        }

        // 
        aspList.push(aspData);
      }

      // 
      return aspList
    },
    similarAspGroupedAspList(state, getters) {
      let newAspDataList = [];
      let loopIndex = 0;
      let famData = null;
      let famAdIndex = 0;

      let aspList = getters.aspList;

      aspList.forEach((aspData) => {
        //
        if (aspData.aspId === "81") {
          // famのデータを保持
          famData = aspData;
        } else {
          if (aspData.aspId === "7") {
            // famADのindexを記録
            famAdIndex = loopIndex;
          } else {
            //
          }
          newAspDataList.push(aspData);
        }
        loopIndex++;
      });

      //
      if (famData !== null) {
        newAspDataList.splice(famAdIndex + 1, 0, famData);
      } else {
        // no action.
      }

      // 
      return newAspDataList
    },
    asps(state, getters) {
      return getters.aspList
    },
    aspData: (state, getters) => (aspId) => {
      if (aspId === '') {
        return {};
      } else {
        return getters.cartDiscardedAspList.find(aspData => aspData.aspId === aspId);
      }
    },
    aspDataFromAllAspList: (state) => (aspId) => {
      return state.asps.find(aspData => aspData.aspId === aspId);
    },
    bonusPointList(state) {
      return state.bonusPointList
    },
    bonusPointData: (state) => (toolCode, bonusCode) => {
      return state.tools[toolCode].bonusPointList.find(bonusData => bonusData.code === bonusCode);
    },
    stockPoint(state) {
      return state.stockPoint
    },
    productList(state) {
      return state.productList
    },
    activePlanData(state) {
      return state.activePlanData
    },
    spentAmountsThisMonthInEachTool(state) {
      return state.spentAmountsThisMonthInEachTool
    },
    supportingImportAspList(state, getters) {
      let aspList = getters.aspList;
      let supportingImportAspList = [];
      aspList.forEach(aspData => {
        if (aspData.supportsImport === '1') {
          supportingImportAspList.push(aspData);
        } else {
          return;
        }
      });
      return supportingImportAspList;
    },
    aspNames(state) {
      let asps = JSON.parse(JSON.stringify(state.asps));
      let aspNames = [];
      asps.forEach(asp => {
        aspNames[asp.aspId] = asp.aspName;
      });
      return aspNames;
    },
    aspManualUrls(state) {
      let asps = JSON.parse(JSON.stringify(state.asps));
      let aspManualUrls = [];
      asps.forEach(asp => {
        let manualUrls = {
          adminUrl: asp.adminUrl,
          aspPointBackManual: asp.aspPointBackManual,
          aspPnameManual: asp.aspPnameManual,
          pidManualUrl: asp.pidManualUrl
        };
        aspManualUrls[asp.aspId] = manualUrls;
      });
      return aspManualUrls;
    },
    uploadsFolder(state) {
      return state.uploadsFolder
    },
    tool(state) {
      let toolData = state.tool;
      if (state.tool.codeCamel !== '') {
        toolData.id = parseInt(state.tools[state.tool.codeCamel].id);
      } else {
        toolData.id = 0;
      }
      return toolData;
    },
    // currentPageToolData(state) {
    //   return state.currentPageToolData
    // },
    tools(state) {
      return state.tools
    },
    toolData: (state) => (toolId) => {
      toolId = String(toolId);
      return state.toolList.find(toolData => toolData.toolId === toolId);
    },
    toolList(state) {
      return state.toolList
    },
    topPageToolList(state) {
      // 取得
      let topPageToolList = state.toolList.filter((toolData) =>
        toolData.topPage !== "0" && toolData.enable === "1"
      );

      // 並び替え
      topPageToolList = topPageToolList.sort(function (a, b) {
        return a.topPage - b.topPage;
      });

      // 
      return topPageToolList;
    },
    lastVisitedToolCode(state) {
      return state.lastVisitedToolCode
    },
    apMinValue(state) {
      return state.apMinValue
    },
    apMaxValue(state) {
      return state.apMaxValue
    },
    registeredCardList(state) {
      return state.registeredCardList
    },
    imgLimit(state) {
      return state.imgLimit
    },
    imgLimitMessage(state) {
      return state.imgLimitMessage
    },
    imgTotalLimit(state) {
      return state.imgTotalLimit
    },
    imgTotalLimitMessage(state) {
      return state.imgTotalLimitMessage
    },
    imgTotalCount(state) {
      return state.imgTotalCount
    },
    imgTotalCountMessage(state) {
      return state.imgTotalCountMessage
    },
    newsOpen(state) {
      return state.newsOpen
    },
    backButton(state) {
      return state.backButton
    },
    formatEditFrom(state) {
      return state.formatEditFrom
    },
    copyPageInfo(state) {
      let list = {
        id: state.copyPageId,
        tool: state.copyPageTool
      }
      return list;
    },
    formatList(state) {
      return state.formatList;
    },
    ABTestDetailInfo(state) {
      return state.ABTestDetailInfo;
    },
    QAList(state) {
      return state.QAList;
    },
    openSidebar(state) {
      return state.openSidebar;
    },
    apiErrorMessage(state) {
      return state.apiErrorMessage;
    },
    bankAccountTypeList(state) {
      return state.bankAccountTypeList;
    },
    bankAccountTypeData: (state) => (typeId) => {
      let bankAccountTypeData = state.bankAccountTypeList.find(typeData => typeData.typeId === typeId);
      return bankAccountTypeData;
    },
    tutorialList: (state) => (toolId) => {
      toolId = String(toolId);
      let toolsTutorialData = state.tutorialList.find(toolData => toolData.toolId === toolId);
      return toolsTutorialData.categoryList;
    },
    importErrorTypeList(state) {
      return state.importErrorTypeList;
    },
    domainList(state) {
      return state.domainList;
    },
    domainData: (state) => (id) => {
      const domainData = state.domainList.find(domainData => domainData.id === id);
      return domainData;
    },
    privilegeList(state) {
      return state.privilegeList;
    },
    privilegeConditionList(state) {
      return state.privilegeConditionList;
    },
    conditionGroupedPrivilegeList(state) {
      let conditionGroupedPrivilegeList = [];

      // 
      const privilegeConditionList = JSON.parse(JSON.stringify(state.privilegeConditionList));
      privilegeConditionList.forEach(function (privilegeConditionData) {

        // 
        let privilegeList = state.privilegeList.filter((privilegeData) =>
          privilegeData.conditionId === privilegeConditionData.conditionId &&
          (privilegeData.toolId === String(state.tool.id) || privilegeData.toolId === "0")
        );

        // 
        if (privilegeList.length > 0) {
          privilegeConditionData['privilegeList'] = privilegeList;
          conditionGroupedPrivilegeList.push(privilegeConditionData);
        } else {
          // no action.
        }
      });

      // 
      return conditionGroupedPrivilegeList;
    },
  },
  mutations: {
    setRootUrl(state, payload) {
      state.rootUrl = payload;
    },
    setRt(state, payload) {
      state.rt = payload;
    },
    setConstData(state, payload) {
      state.constData = payload.constData;
    },
    setUserData(state, payload) {
      state.userData = payload;
    },
    setLogin(state, payload) {
      state.login = payload;
    },
    setAsps(state, payload) {
      state.asps = payload;
    },
    setProductList(state, payload) {
      state.productList = payload;
    },
    setActivePlanData(state, payload) {
      state.activePlanData = payload;
    },
    setBonusPointList(state, payload) {
      state.bonusPointList = payload;
    },
    setStockPoint(state, payload) {
      state.stockPoint = payload;
    },
    setSpentAmountsThisMonthInEachTool(state, payload) {
      state.spentAmountsThisMonthInEachTool = payload;
    },
    toggleNewsOpen(state, payload) {
      state.newsOpen = payload;
    },
    setImgLimit(state, payload) {
      state.imgLimit = payload.limit;
      state.imgLimitMessage = payload.mess;
    },
    setImgTotalLimit(state, payload) {
      state.imgTotalLimit = payload.limit;
      state.imgTotalLimitMessage = payload.mess;
    },
    setImgTotalCount(state, payload) {
      state.imgTotalCount = payload.limit;
      state.imgTotalCountMessage = payload.mess;
    },
    toggleOptions(state, payload) {
      state.backButton = payload.backButton;
    },
    setToolData(state, object) {
      state.tool = object;
    },
    // setCurrentPageToolData(state, payload) {
    //   const toolCodeKebab = payload.toolCodeKebab;
    //   const toolList = state.toolList;
    //   const currentPageToolData = toolList.find(toolData => toolData.codeKebab === toolCodeKebab);
    //   state.currentPageToolData = currentPageToolData;
    // },
    setTools(state, array) {
      state.tools = array;
    },
    setToolList(state, array) {
      state.toolList = array;
    },
    setLastVisitedToolCode(state, payload) {
      state.lastVisitedToolCode = payload;
    },
    setApValue(state, payload) {
      state.apMinValue = payload.min;
      state.apMaxValue = payload.max;
    },
    setRegisteredCardList(state, payload) {
      state.registeredCardList = payload;
    },
    setFormatEditFrom(state, payload) {
      state.formatEditFrom = payload;
    },
    setCopyPageInfo(state, payload) {
      state.copyPageTool = payload.tool;
      state.copyPageId = payload.id;
    },
    setFormatList(state, payload) {
      state.formatList = payload;
    },
    setABTestDetailInfo(state, payload) {
      state.ABTestDetailInfo = payload;
    },
    setQAList(state, payload) {
      state.QAList = payload;
    },
    setOpenSidebar(state, payload) {
      state.openSidebar = payload;
    },
    setApiErrorMessage(state, payload) {
      state.apiErrorMessage = payload;
    },
    setBankAccountTypeList(state, payload) {
      state.bankAccountTypeList = payload;
    },
    setTutorialList(state, payload) {
      state.tutorialList = payload;
    },
    setImportErrorTypeList(state, payload) {
      state.importErrorTypeList = payload;
    },
    setPrivilegeList(state, payload) {
      state.privilegeList = payload;
    },
    setprivilegeConditionList(state, payload) {
      state.privilegeConditionList = payload;
    },
    setDomainList(state, payload) {
      state.domainList = payload.domainList;
    },
    // setConditionGroupedPrivilegeList(state, payload) {
    //   state.conditionGroupedPrivilegeList = payload;
    // },
  },
  actions: {
    setVariousValuesForAllUsers({
      getters,
      commit,
      dispatch
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${getters.rt}/api/getVariousValue.php`)
          .then(res => {
            console.log(res);

            // commit
            commit('setApiErrorMessage', res.data.apiErrorMessage);
            commit('setBankAccountTypeList', res.data.bankAccountTypeList);
            commit('setImgLimit', {
              limit: res.data.imgLimit,
              mess: res.data.imgLimitMess
            });
            commit('setImgTotalLimit', {
              limit: res.data.imgTotalLimit,
              mess: res.data.imgTotalLimitMess
            });
            commit('setImgTotalCount', {
              limit: res.data.imgTotalCount,
              mess: res.data.imgTotalCountMess
            });
            commit('setApValue', {
              min: res.data.apMinValue,
              max: res.data.apMaxValue
            });
            commit('setTools', res.data.tools);
            commit('setToolList', res.data.toolList);
            commit('setQAList', res.data.qaList);
            commit('setBonusPointList', res.data.bonusPointList);
            commit('setImportErrorTypeList', res.data.importErrorTypeList);
            commit('tools02/setAccountTypeNames', res.data.googleCvAccountTypesNames);

            //dispatch
            dispatch('tools02/setInitialData', res.data.googleCvAccountInitialValues);

            // affiliate
            let payload = {
              rewardFeeWithTax: res.data.affiliateRewardFeeWithTax,
              rewardBorderWithTax: res.data.affiliateRewardBorderWithTax
            };
            commit('affiliate/setConsts', payload);

            //
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      })
    },
    setVariousValuesForLoggedInUser({
      commit,
      dispatch
    }, payload) {
      dispatch('setUserData');
      dispatch('setLogin', 1);
      dispatch('setAsps', payload.aspList);
      dispatch('setActivePlanData');
      dispatch("setRegisteredCardList");
      dispatch('setProductList');
      dispatch('subscription/setValidities');
      dispatch("setTutorialList");
      dispatch("setprivilegeConditionList");
      dispatch("setConstData");
      // commit('setConditionGroupedPrivilegeList');
      commit('setPrivilegeList', payload.privilegeList);
      dispatch("setDomainList");

      // tools01
      dispatch('tools01/setPageList');
      dispatch('tools01/setAbTestList');
      dispatch('tools01/setFormatList');

      // tools02
      dispatch('tools02/setUnits');
      dispatch('tools02/setWithTrashedPromotionList');
      dispatch('tools02/setSubscriptionList');
      dispatch('tools02/setAccounts');
      dispatch('tools02/setPixels');
      dispatch('tools02/setTagList');
      // dispatch('tools02/setUnitList'); // apiがない
      dispatch('tools02/setBussinessAccountList');
      dispatch('tools02/setAutoImportCodes', payload.autoImportCodes);
      dispatch('tools02/setFacebookEventList');
      dispatch('tools02/setTiktokEventList');
      dispatch('tools02/setCartSystemSubscriptionList');
      dispatch('tools02/setCartSystemProductList');
      dispatch('tools02/setParameterPassingLogTypeList');
      dispatch('tools02/setImportResultDefinitionList');

      // affiliate
      dispatch('affiliate/setPrivilegeData');
    },
    checkSession({
      getters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${getters.rt}/api/checkSession.php`)
          .then(res => {
            console.log(res);
            resolve(res.data.status);
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      })
    },
    redirect({
      getters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${getters.rt}/api/payment/getRetUrl.php`)
          .then(res => {
            console.log(res);
            resolve(res.data);
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      })
    },
    setRootUrl({
      commit
    }) {
      let rootUrl;
      if (
        process.env.NODE_ENV === 'development' ||
        location.host === 'tools.adtasukaru.localhost'
      ) {
        rootUrl = "http://localhost:8001";
      } else {
        if (location.host === 'tools.adtasukaru-wp.com') {
          rootUrl = "https://adtasukaru-wp.com/l";
        } else {
          rootUrl = "https://adtasukaru.com/l";
        }
      }
      commit('setRootUrl', rootUrl);
    },
    setRt({
      commit
    }) {
      let rt;
      if (
        process.env.NODE_ENV === 'development' ||
        location.host === 'tools.adtasukaru.localhost'
      ) {
        rt = "http://adtasukaru.localhost";
      } else {
        if (location.host === 'tools.adtasukaru-wp.com') {
          rt = "https://adtasukaru-wp.com";
        } else {
          rt = "https://adtasukaru.com";
        }
      }
      commit('setRt', rt);
    },
    setUserData({
      commit,
      getters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${getters.rt}/api/getUserDetail.php`)
          .then(response => {
            if (response.data.status === 200) {
              commit('setUserData', response.data.userData);
              resolve(response);
            } else {
              alert(getters.apiErrorMessage + "(setUserData)");
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    setConstData({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rt}/l/api/const/get-data`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            let payload = {
              constData: response.data.data
            };
            commit('setConstData', payload);
          } else {
            console.log(getters.apiErrorMessage + "（setConstData）");
          }
          return;
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    setLogin({
      commit
    }, payload) {
      commit('setLogin', payload);
    },
    setAsps({
      commit
    }, payload) {
      commit('setAsps', payload);
    },
    setBonusPointList({
      commit
    }, payload) {
      commit('setBonusPointList', payload);
    },
    setStockPoint({
      commit,
      getters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${getters.rt}/api/getStockPoint.php`)
          .then((res) => {
            console.log(res);
            let payload = res.data.stockPoint;
            commit('setStockPoint', payload);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      })
    },
    setProductList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rt}/api/getProductsTable.php`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            commit('setProductList', res.data.productList);
            return;
          } else {
            alert(getters.apiErrorMessage + "（setProductList）");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setActivePlanData({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rt}/api/payment/getActivePlan.php`)
        .then((res) => {
          console.log(res);
          let payload = res.data.pointPlan;
          commit('setActivePlanData', payload);
          return;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setSpentAmountsThisMonthInEachTool({
      commit,
      getters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${getters.rt}/api/getUsePoint.php`)
          .then((res) => {
            console.log(res);
            let payload = res.data.list;
            commit('setSpentAmountsThisMonthInEachTool', payload);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      })
    },
    setRegisteredCardList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rt}/api/payment/get-registered-cards.php`)
        .then((res) => {
          console.log(res);
          if (
            res.data.status === 200 ||
            res.data.status === 400
          ) {
            commit('setRegisteredCardList', res.data.registeredCardList);
            return;
          } else {
            console.log(getters.apiErrorMessage + "（setRegisteredCardList）");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleNewsOpen({
      commit
    }, payload) {
      commit('toggleNewsOpen', payload);
    },
    toggleOptions({
      commit
    }, payload) {
      let backButton;

      switch (payload) {

        // //サイドバーのみ非表示
        // case "Logout":
        //   break;

        // //戻るボタンのみ非表示
        // case "Login":
        //   break;

        //サイドバーと戻るボタン非表示
        case "Logout":
        case "Login":
          backButton = 0;
          break;

        default:
          backButton = 1;
          break;

      }
      commit('toggleOptions', {
        backButton: backButton
      });
    },
    setToolData({
      commit
    }, payload) {
      commit('setToolData', payload);
    },
    // setCurrentPageToolData({
    //   commit
    // }, payload) {
    //   commit('setCurrentPageToolData', payload);
    // },
    setLastVisitedToolCode({
      commit
    }, payload) {
      commit('setLastVisitedToolCode', payload);
    },
    setFormatEditFrom({
      commit
    }, payload) {
      commit('setFormatEditFrom', payload);
    },
    setTutorialList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rt}/api/get-tutorial-list.php`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            commit('setTutorialList', res.data.tutorialList);
            return;
          } else {
            console.log(getters.apiErrorMessage + "（setTutorialList）");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setQAList({
      commit
    }, payload) {
      commit('setQAList', payload);
    },
    switchOpenSidebar({
      commit,
      getters
    }) {
      let payload = getters.openSidebar === true ? false : true;
      commit('setOpenSidebar', payload);
    },





    //mistook...
    setFormatList({
      commit,
      getters
    }, payload) {
      return new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        params.append("tool", payload.tool);
        params.append("site_id", payload.page_id);
        axios
          .post(`${getters.rt}/api/tools01/getFormatList.php`, params)
          .then((res) => {
            console.log(res);
            commit('setFormatList', res.data.list);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      })
    },
    setCopyPageInfo({
      commit,
      getters
    }, parameter) {
      return new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        params.append("parameter", parameter);
        axios
          .post(`${getters.rt}/api/tools01/getCopyFormatList.php`, params)
          .then((res) => {
            console.log(res);
            let payload = {
              'tool': res.data.tool,
              'id': res.data.page_id
            }
            commit('setCopyPageInfo', payload);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      })
    },
    setABTestDetailInfo({
      commit,
      getters
    }, payload) {
      return new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        params.append("tool", payload.tool);
        params.append("page_id", payload.page_id);
        params.append("test_id", payload.test_id);
        axios
          .post(`${getters.rt}/api/tools01/getTestDetail.php`, params)
          .then(res => {
            console.log(res);
            commit('setABTestDetailInfo', res.data.list);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      })
    },
    setprivilegeConditionList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rt}/l/api/privilege/get-condition-list`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            commit('setprivilegeConditionList', res.data.privilegeConditionList);
            return;
          } else {
            console.log(getters.apiErrorMessage + "（setprivilegeConditionList）");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkAndGivesBonusPoint({
      getters,
      dispatch
    }, payload) {
      return new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        params.append("bonus_id", payload.bonusId);
        axios
          .post(`${getters.rt}/api/checkAndGivesBonusPoint.php`, params)
          .then(response => {
            console.log(response);
            if (response.data.status === 200) {
              dispatch("setUserData");
            } else {
              // 
            }
            resolve();
          })
          .catch(error => {
            console.log(error);
            reject();
          });
      })
    },
    setDomainList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rt}/l/api/domain/get-list`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            const payload = {
              domainList: response.data.list
            };
            commit('setDomainList', payload);
          } else {
            console.log(getters.apiErrorMessage + "（setDomainList）");
          }
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  plugins: [
    createPersistedState(),
  ],
  modules: {
    affiliate,
    ctrl,
    subscription,
    tools01,
    tools02,
  }
})