<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>API送信履歴を確認する</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>手動、自動インポートの際に、MetaピクセルへAPI送信した履歴を確認します。</li>
          <li>API送信が成功しているのに、Meta広告上でAPI送信が確認できないデータについて、データの再送をします。</li>
          <li>API送信が失敗している場合は、原因を確認します。</li>
        </ul>
        <h4>【１】Meta広告コンバージョンAPI連携ツールのトップページから、「API送信履歴」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no02/01.png`" alt="" />
        <h4>【２】検索したい条件を指定して、検索することができます。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no02/02.png`" alt="" />
        <h4>【３】Meta広告にAPI送信された成果の送信履歴が表示されます。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no02/03.png`" alt="" />
        <h4>【「API送信結果」が「失敗」の場合の対処法】</h4>
        <p>エラータイトル、エラーメッセージを確認して、対処してください。</p>
        <p>対処方法が分からない場合は「API送信履歴のCSV」を添付して、サポートにお問い合わせください。</p>
        <table id="error_tool_tag_empty" class="error">
          <tr>
            <th>エラーコード</th>
            <th>エラータイトル</th>
            <th>エラーメッセージ</th>
            <th>エラーの原因</th>
            <th>対処法</th>
          </tr>
          <tr>
            <td>33</td>
            <td></td>
            <td></td>
            <td>ピクセルIDが異なる</td>
            <td>ピクセル登録を修正して、再度、手動インポートする</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>アクセストークンが異なる</td>
            <td>ピクセル登録を修正して、再度、手動インポートする</td>
          </tr>
          <tr>
            <td>2804050</td>
            <td>このイベントの十分な顧客情報パラメーターデータが追加されていません</td>
            <td>このイベントには顧客情報パラメーターがないか、広範すぎてマッチングに効果的ではない顧客情報パラメーターの組み合わせがあります。これを修正するには、developers.facebook.com/docs/marketing-api/conversions-api/best-practices/#req-rec-paramsでパラメーターのベストプラクティスを確認してくださ</td>
            <td>CSVをサポートに送ってください</td>
            <td></td>
          </tr>
          <tr>
            <td>2804003</td>
            <td>イベントタイムスタンプが古すぎます</td>
            <td>このイベントのタイムスタンプとして古すぎる値が送信されました。イベントは、7日以内のイベント発生時点で送信される必要があります。過去7日以内の、イベント発生時点のタイムスタンプを入力してください。</td>
            <td>7日以上前に発生した成果をインポートしたから</td>
            <td>ASPの成果発生日時を7日以内に修正して、再度、手動インポートする</td>
          </tr>
        </table>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no02/04.png`" alt="" />
        <p>確認画面が表示されるので「OK」をクリックしてください。</p>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no10/no02/05.png`" alt="" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.error {
  width: 100%;
  border-collapse: collapse;
  margin-left: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px){
    margin-left: 0!important;
  }
  & + .error {
    margin-top: 20px;
  }
  th,
  td {
    border: 1px solid;
    padding: 15px;
  }
  th {
    background-color: #e5e5e5;
  }
}
</style>
