<template>
  <div class="manual-google-cv-contents">
    <h2>合算イベント測定を設定する</h2>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <p>Apple社からiOS 14.5のリリースにより、Meta広告でウェブコンバージョンイベントの最適化・計測、およびそれに伴うオーディエンス作成を行っているビジネスは「合算イベント測定」の設定が必要になったので、その対応をします。</p>
        <h3>【所要時間】</h3>
        <p>3分</p>

        <h4>
          【１】データソースの画面へアクセスし、合算イベント測定を設定したいピクセルをクリックします（データソースへのアクセスは、「<router-link :to="`/manual/${tool.codeKebab}/01/02`">データソースへのアクセス方法</router-link>」を参照）。
        </h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/01.png`" alt="" />
        <h4>【２】「概要」タブの画面を下へスクロールし、「合算イベント測定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/02.png`" alt="" />
        <h4>【３】「ウェブイベントを設定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/03.png`" alt="" />
        <h4>【４】対象のドメインをクリックします。設定したいドメインが表示されない場合は、先にドメイン認証を行ってください（参照：<router-link :to="`/manual/${tool.codeKebab}/03/11`">ドメイン認証</router-link>）。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/04.png`" alt="" />
        <h4>【５】「イベントを管理」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/05.png`" alt="" />
        <h4>【６】「編集」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/06.png`" alt="" />
        </div>
        <h4>【７】「イベントを追加」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/07.png`" alt="" />
        <h4>【８】「ピクセル/カスタムコンバージョン」の欄から合算イベント測定に用いるピクセルを選択し、「イベント名」は計測したいイベントを選択し、「適用」をクリックします。</h4>
        <p class="red">※アドタスカルでは、プログラム登録でコンバージョンイベントを選択することができます。（参照：<router-link :to="`/manual/${tool.codeKebab}/05/01`">プログラム登録</router-link>）</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/08.png`" alt="" />
        <h4>【９】「はい、これらの変更を確認しました。」にチェックを入れ、「適用」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/09.png`" alt="" />
        </div>
        <h4>【１０】以下の画面が表示されるので、「OK」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no12/10.png`" alt="" />
        </div>
        <p>以上で、合算イベント測定の設定は完了です。</p>
        <p>「<router-link :to="`/manual/${tool.codeKebab}/04/06`">ユニット登録</router-link>」に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
